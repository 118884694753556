import { useState, MouseEvent } from 'react'
import styled from 'styled-components'

const MainDiv = styled.div`
  margin-bottom: 20px;
`

const StatusLink = styled.span`
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: underline;

  &.active {
    cursor: default;
    text-decoration: none;
  }
`

export enum ProductStatusFilterStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALL = 'all'
}

interface ProductStatusFilterProps {
  status?: ProductStatusFilterStatus
  onChange: (status: ProductStatusFilterStatus) => void
}

const ProductStatusFilter = ({ status, onChange }: ProductStatusFilterProps) => {
  const [currentStatus, setCurrentStatus] = useState<ProductStatusFilterStatus | undefined>(status)

  const changeStatus = (newStatus: ProductStatusFilterStatus, event: MouseEvent<HTMLSpanElement>) => {
    setCurrentStatus(newStatus)
    onChange(newStatus)
    event.currentTarget.blur()
  }

  return (
    <MainDiv>
      <span>Product Status:</span>
      <StatusLink
        className={'status-active' + (currentStatus === ProductStatusFilterStatus.ACTIVE ? ' active' : '')}
        onClick={event => changeStatus(ProductStatusFilterStatus.ACTIVE, event)}>
        active
      </StatusLink>{' '}
      |
      <StatusLink
        className={'status-inactive' + (currentStatus === ProductStatusFilterStatus.INACTIVE ? ' active' : '')}
        onClick={event => changeStatus(ProductStatusFilterStatus.INACTIVE, event)}>
        inactive
      </StatusLink>{' '}
      |
      <StatusLink
        className={'status-all' + (currentStatus === ProductStatusFilterStatus.ALL ? ' active' : '')}
        onClick={event => changeStatus(ProductStatusFilterStatus.ALL, event)}>
        all
      </StatusLink>
    </MainDiv>
  )
}

export default ProductStatusFilter

import moment from 'moment'

export const formatCurrency = (amount: number | string | null | undefined, currencyCode = 'AUD') => {
  if (amount == null) return

  // Convert string amount to number if needed
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount

  // Check if conversion resulted in a valid number
  if (isNaN(numericAmount)) return

  const format = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode
  })

  return format.format(numericAmount)
}

export const formatDate = (dateString: string | Date, format = 'DD/MM/YYYY'): string => {
  if (!dateString) return ''

  try {
    return moment(dateString).format(format)
  } catch (error) {
    console.error('Error formatting date:', error)
    return ''
  }
}

import { tableClasses } from './table-classes'
import Icon from '~/components/icon'
import { cn } from '~/lib/utils'

// Types for sorting
export type SortDirection = 'asc' | 'desc' | null

// Sortable header cell component
interface SortableHeaderProps<T> {
  field: keyof T | null
  label: string
  currentSortField: keyof T | null
  sortDirection: SortDirection
  onSort: (field: keyof T | null) => void
  align?: 'left' | 'right'
}

export function SortableHeader<T>({
  field,
  label,
  currentSortField,
  sortDirection,
  onSort,
  align = 'left'
}: SortableHeaderProps<T>) {
  const getSortIcon = () => {
    if (field !== currentSortField) {
      return <Icon icon="sort" className="text-gray-300" size="sm" />
    }

    if (sortDirection === 'asc') {
      return <Icon icon="arrow-up" className="text-blue-500" size="sm" />
    } else if (sortDirection === 'desc') {
      return <Icon icon="arrow-down" className="text-blue-500" size="sm" />
    }

    return <Icon icon="sort" className="text-gray-300" size="sm" />
  }

  const alignClass = align === 'left' ? tableClasses.headerLeft : tableClasses.headerRight

  return (
    <th
      scope="col"
      className={`${tableClasses.headerBase} ${tableClasses.headerSortable} ${alignClass}`}
      onClick={() => onSort(field)}>
      <div className={cn(`flex items-center gap-1`, align === 'right' && 'justify-end')}>
        <span>{label}</span>
        {getSortIcon()}
      </div>
    </th>
  )
}

// Reusable cell component
interface TableCellProps {
  children: React.ReactNode
  align?: 'left' | 'right'
  className?: string
}

export const TableCell = ({ children, align = 'left', className }: TableCellProps) => {
  const alignClass = align === 'right' ? tableClasses.cellRight : ''
  return <td className={cn(tableClasses.cell, alignClass, className)}>{children}</td>
}

import moment from 'moment'
import _ from 'lodash'

import { ShipmentStatusEnum } from '~/types/au-post-shipment'
import Order from '~/types/order'
import Icon from '~/components/icon'

const renderShipmentStatus = (status: ShipmentStatusEnum) => {
  switch (status) {
    case 'no_shipment':
      return null
    case 'has_errors':
      return <Icon icon="times" className="text-danger" title="has_errors" />
    case 'not_despatched':
      return <span className="label label-warning">ND</span>
    case 'despatched':
      return <Icon icon="check" className="text-success" title="despatched" />
    case 'combined_not_despatched':
      return (
        <>
          <span className="label label-warning" style={{ marginRight: '10px' }}>
            ND
          </span>
          <span className="label label-warning">C</span>
        </>
      )
    case 'combined_despatched':
      return (
        <>
          <Icon icon="check" className="text-success" title="despatched" style={{ marginRight: '10px' }} />
          <span className="label label-warning">C</span>
        </>
      )
    default:
      return <span className="label label-danger">{status}</span>
  }
}

export const gridColumns = [
  {
    attr: 'reference',
    heading: 'Order',
    locked: true
  },
  {
    attr: 'created',
    render: (order: Order) => moment((order.mainSalesRecord || {}).createdAt || order.createdAt).format('DD/MM/YYYY')
  },
  {
    attr: 'ordered',
    render: (order: Order) => moment(order.orderDate).format('DD/MM/YYYY')
  },
  {
    attr: 'shippingContactName',
    heading: 'Name'
  },
  {
    attr: 'quantity',
    render: (order: Order) => {
      let totalQty = 0
      _.each(order.lineItems, lineItem => {
        totalQty += lineItem.quantity
      })
      return totalQty
    }
  },
  {
    attr: 'itemDescription',
    render: (order: Order) => {
      const getCombinationDescription = (order: Order) => {
        return _.sumBy(order.lineItems, 'quantity') + ' x COMBINATION'
      }

      const description =
        order.lineItems.length <= 1
          ? order.lineItems[0]?.importedScancode || order.mainSalesRecord?.itemTitle
          : getCombinationDescription(order)

      return description || ((_.first(order.lineItems) || {}).product || {}).name
    }
  },
  {
    attr: 'shippingService',
    heading: 'Service'
  },
  {
    attr: 'shippingCountry',
    heading: 'Country'
  },
  {
    attr: 'shipment',
    render: (o: Order) => renderShipmentStatus(o.shipmentStatus)
  },
  {
    attr: 'status',
    render: (order: Order) => {
      if (order.status === 'unprocessed') {
        return <Icon icon="exclamation-circle" className="text-warning" title="unprocessed" />
      } else if (order.status === 'processed') {
        return <Icon icon="check" className="text-success" title="processed" />
      } else if (order.status === 'picked') {
        return (
          <span className="d-flex gap-2">
            <Icon icon="check" className="text-success" title="processed" />
            <Icon icon="box" className="text-info" title="picked" />
          </span>
        )
      }
    }
  }
]

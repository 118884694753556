import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext, Field } from 'formik'
import _ from 'lodash'
import styled from 'styled-components'
import RichTextEditor from '~/components/form-inputs/formik/input-types/rich-text-editor'

import { BasicCheckbox, BsInput, BsTextArea } from '~/components/form-inputs/formik/inputs-decorated'
import CustomDataSection from '../custom-data-section'
import RegionalPricingSection, {
  SelectedPricingProfileIds,
  RegionalPricingSectionTypeEnum,
  GetWeightBasedShippingProfileType
} from '../regional-pricing-section'
import DropshipPricingSection from '../dropship-pricing-section'
import { findWeightBasedShippingProfile, transformWeightBasedProfile } from '../utils'
import { loadItems as loadShippingPricingProfiles } from '~/async-actions/shipping-pricing-profiles-async-actions'
import { loadItems as loadWeightBasedShippingPricingProfiles } from '~/async-actions/weight-based-shipping-pricing-profiles-async-actions'
import useAuth from '~/contexts/useAuth'
import { ProductFormValues } from '../use-product-form'
import { AppDispatch, AppState } from '~/config/store'

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const DescriptionRow = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

interface MobileStoreProps {
  initialValues: ProductFormValues
}

const MobileStore = ({ initialValues }: MobileStoreProps) => {
  const [shippingLoaded, setShippingLoaded] = useState(false)
  const [weightBasedShippingLoaded, setWeightBasedShippingLoaded] = useState(false)
  const { values: formValues, setFieldValue } = useFormikContext<ProductFormValues>()
  const { userProfile } = useAuth()
  const dispatch = useDispatch<AppDispatch>()
  const {
    items: shippingProfiles,
    loading: { loadItems: shippingProfilesLoading }
  } = useSelector((state: AppState) => state.shippingPricingProfiles)
  const {
    items: weightBasedShippingPricingProfiles,
    loading: { loadItems: weightBasedShippingPricingProfilesLoading }
  } = useSelector((state: AppState) => state.weightBasedShippingPricingProfiles)
  const shopfrontOnlyMode = Boolean(userProfile?.accountSettings?.shopfrontOnlyMode)

  const defaultGoogleProductFeedFields = () => {
    if (!formValues.ageGroup && formValues.showInGoogleProductsFeed) {
      setFieldValue('ageGroup', 'adult')
    }
  }

  const getDescriptionFormat = () => {
    if (initialValues?.usesHtmlDescription === true) {
      return 'html'
    }
    return 'markdown'
  }

  const addCustomDataField = () => {
    setFieldValue('customData', [...(formValues.customData || []), { key: '', value: '' }])
  }

  const removeCustomDataField = (index: number) => {
    const updatedCustomData = [...(formValues.customData || [])]
    updatedCustomData.splice(index, 1)
    setFieldValue('customData', updatedCustomData)
  }

  const profiles = {
    regular: _.filter(shippingProfiles, ['type', RegionalPricingSectionTypeEnum.REGULAR]),
    express: _.filter(shippingProfiles, ['type', RegionalPricingSectionTypeEnum.EXPRESS]),
    discount: _.filter(shippingProfiles, ['type', RegionalPricingSectionTypeEnum.DISCOUNT])
  }

  let regular = formValues.regularShippingProfileId
  let express = formValues.expressShippingProfileId
  const discount = formValues.discountShippingProfileId

  if (formValues.regularSingleItemWeightBasedShipping === true) {
    regular = 'weight_based_regular'
  }

  if (formValues.expressSingleItemWeightBasedShipping === true) {
    express = 'weight_based_express'
  }

  const selectedShippingProfileIds = {
    regular,
    express,
    discount
  } as SelectedPricingProfileIds

  const getWeightBasedShippingProfile: GetWeightBasedShippingProfileType = useCallback(
    type => {
      const packageWeight = formValues?.packageWeight
      const profile = findWeightBasedShippingProfile(weightBasedShippingPricingProfiles, type, packageWeight)
      const transformedProfile = transformWeightBasedProfile(packageWeight, profile)
      console.log('[DEBUG] packageWeight =', packageWeight)
      console.log('[DEBUG] profile =', profile)
      console.log('[DEBUG] transformWeightBasedProfile =', transformWeightBasedProfile)
      return transformedProfile
    },
    [formValues?.packageWeight, weightBasedShippingPricingProfiles]
  )

  useEffect(() => {
    const loadInitialValues = async () => {
      if (shippingProfilesLoading || weightBasedShippingPricingProfilesLoading) {
        return
      }

      if (!shippingProfiles.length && !shippingLoaded) {
        setShippingLoaded(true)
        await dispatch(loadShippingPricingProfiles())
      }

      if (!weightBasedShippingPricingProfiles.length && !weightBasedShippingLoaded) {
        setWeightBasedShippingLoaded(true)
        await dispatch(loadWeightBasedShippingPricingProfiles())
      }
    }
    loadInitialValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper className="row">
      <div className="col-sm-12">
        <fieldset>
          <legend>Mobile Store</legend>

          <div>
            <div className="row">
              <div className="col-sm-8">
                <Field
                  name="mobileName"
                  component={BsInput}
                  label="Online Name"
                  helpBlock={!formValues.mobile || formValues.mobileName ? '' : "Uses 'POS Name' when blank"}
                />
                <Field
                  name="mobileNameShort"
                  component={BsInput}
                  label="Online Name (Short)"
                  helpBlock={
                    !formValues.mobile || formValues.mobileNameShort
                      ? ''
                      : formValues.mobileName
                      ? "Uses 'Online Name' when blank"
                      : "Uses 'POS Name' when blank"
                  }
                />
                <Field
                  name="thumbnailName"
                  component={BsInput}
                  label="Thumbnail Name"
                  helpBlock="Use if you want a custom name for product grid thumbnails. If blank, 'Online Name (short)' will be used."
                />
                <Field
                  name="seoTitle"
                  component={BsInput}
                  label="Page Title"
                  helpBlock="If blank 'Online Name' will be used"
                />
                <Field name="seoMetaDescription" component={BsTextArea} label="SEO Meta Description" />
                <Field name="salePrice" label="Sale Price" component={BsInput} />
              </div>
              <div className="col-sm-4">
                <Field
                  name="mobile"
                  title="Show in mobile app ?"
                  component={BasicCheckbox}
                  helpBlock={
                    formValues.mobile && !_.find(formValues.images, { mobile: true })
                      ? 'There must be at least one mobile-enabled image for this product to appear in the app'
                      : ''
                  }
                />
                <Field name="featured" component={BasicCheckbox} title="Featured ?" />
                <Field name="mobileCheckoutEnabled" title="Checkout enabled?" component={BasicCheckbox} />
                <Field name="mobileShowDescription" title="Show description?" component={BasicCheckbox} />
                <Field name="mobileShowSpecifications" title="Show specifications?" component={BasicCheckbox} />
                <Field
                  name="showInGoogleProductsFeed"
                  title="Show in Google Products Feed?"
                  component={BasicCheckbox}
                  onChange={defaultGoogleProductFeedFields}
                />
              </div>
            </div>

            <DescriptionRow className="row">
              <div className="col-sm-12 col-md-7">
                <h4>Mobile Description</h4>
                <Field
                  name="description"
                  label="Description / Keywords"
                  component={RichTextEditor}
                  editorSourceFormat={getDescriptionFormat()}
                />
              </div>

              <div className="col-sm-12 col-md-5">
                <h4>Mobile Specifications</h4>
                <CustomDataSection onAdd={addCustomDataField} onRemove={removeCustomDataField} />
              </div>
            </DescriptionRow>

            {!shopfrontOnlyMode && (
              <RegionalPricingSection
                profiles={profiles}
                selectedIds={selectedShippingProfileIds}
                salePrice={formValues.salePrice || null}
                getWeightBasedShippingProfile={getWeightBasedShippingProfile}
              />
            )}

            <DropshipPricingSection shopfrontOnlyMode={shopfrontOnlyMode} />
          </div>
        </fieldset>
      </div>
    </Wrapper>
  )
}

export default MobileStore

import { Link } from 'react-router-dom'

import { tableClasses } from './table-classes'
import { DenormalizedInventoryItem, GroupBy } from './types'
import { SortDirection } from './TableComponents'
import InventoryTable from './InventoryTable'
import ProductStatusBadge from './ProductStatusBadge'

interface InventoryGroupProps {
  groupKey: string
  items: DenormalizedInventoryItem[]
  groupBy: GroupBy
  sortField: keyof DenormalizedInventoryItem | null
  sortDirection: SortDirection
  onSort: (field: keyof DenormalizedInventoryItem | null) => void
}

const InventoryGroup: React.FC<InventoryGroupProps> = ({
  groupKey,
  items,
  groupBy,
  sortField,
  sortDirection,
  onSort
}) => {
  // Get the title for the group based on grouping type
  const getGroupTitle = () => {
    if (groupBy === 'product') {
      const item = items[0]
      return (
        <div>
          <h3 className="text-lg font-medium text-gray-900">
            <Link to={`/products/${item.productId}/edit`} className={tableClasses.link}>
              {item.productName}
            </Link>
          </h3>
          <div className="flex space-x-2 text-sm text-gray-500 items-center">
            <span>ID: {item.productId}</span>
            <span>•</span>
            <ProductStatusBadge status={item.productStatus} />
          </div>
        </div>
      )
    } else if (groupBy === 'invoice_no') {
      return (
        <h3 className="text-lg font-medium text-gray-900">
          Invoice #
          <Link
            to={`/reports/products-for-invoice-or-supplier?invoice_no=${encodeURIComponent(
              groupKey
            )}&group_by=invoice_no`}
            className={tableClasses.link}>
            {groupKey}
          </Link>
        </h3>
      )
    } else if (groupBy === 'supplier_name') {
      return (
        <h3 className="text-lg font-medium text-gray-900">
          Supplier:
          <Link
            to={`/reports/products-for-invoice-or-supplier?supplier_name=${encodeURIComponent(
              groupKey
            )}&group_by=supplier_name`}
            className={`${tableClasses.link} ml-1`}>
            {groupKey}
          </Link>
        </h3>
      )
    }

    return null
  }

  return (
    <div className={tableClasses.card}>
      <InventoryTable
        items={items}
        groupBy={groupBy}
        sortField={sortField}
        sortDirection={sortDirection}
        onSort={onSort}
        groupTitle={getGroupTitle()}
      />
    </div>
  )
}

export default InventoryGroup

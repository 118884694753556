import { useState, MouseEvent } from 'react'
import styled from 'styled-components'

const MainDiv = styled.div`
  display: flex;
  align-items: center;
`

const StatusLink = styled.span`
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: underline;

  &.active {
    cursor: default;
    text-decoration: none;
  }
`

export enum ProductInventoryFilterStatus {
  IN_STOCK = 'in_stock',
  OUT_OF_STOCK = 'out_of_stock',
  ALL = 'all'
}

interface ProductInventoryStatusFilterProps {
  inventoryStatus?: ProductInventoryFilterStatus
  onChange: (status: ProductInventoryFilterStatus) => void
}

const ProductInventoryStatusFilter = ({ inventoryStatus, onChange }: ProductInventoryStatusFilterProps) => {
  const [currentStatus, setCurrentStatus] = useState<ProductInventoryFilterStatus>(
    inventoryStatus || ProductInventoryFilterStatus.ALL
  )

  const changeStatus = (newStatus: ProductInventoryFilterStatus, event: MouseEvent<HTMLSpanElement>) => {
    setCurrentStatus(newStatus)
    onChange(newStatus)
    event.currentTarget.blur()
  }

  return (
    <MainDiv>
      <span>Inventory Status:</span>
      <StatusLink
        className={'inventory-all' + (currentStatus === ProductInventoryFilterStatus.ALL ? ' active' : '')}
        onClick={event => changeStatus(ProductInventoryFilterStatus.ALL, event)}>
        all
      </StatusLink>{' '}
      |
      <StatusLink
        className={'inventory-in-stock' + (currentStatus === ProductInventoryFilterStatus.IN_STOCK ? ' active' : '')}
        onClick={event => changeStatus(ProductInventoryFilterStatus.IN_STOCK, event)}>
        in stock
      </StatusLink>{' '}
      |
      <StatusLink
        className={
          'inventory-out-of-stock' + (currentStatus === ProductInventoryFilterStatus.OUT_OF_STOCK ? ' active' : '')
        }
        onClick={event => changeStatus(ProductInventoryFilterStatus.OUT_OF_STOCK, event)}>
        out of stock
      </StatusLink>
    </MainDiv>
  )
}

export default ProductInventoryStatusFilter

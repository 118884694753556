import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import Icon_ from '~/components/icon'
import useAuth from '~/contexts/useAuth'
import LogoutMenuItem from './logout-menu-item'
import User from '~/types/user'

export const Icon = styled(Icon_)`
  margin-right: 1rem;
  width: 20px !important;
`
const MenuWrapper = styled.div`
  background-color: ${props => props.theme.SideBar.Menu.backgroundColor};
  min-height: calc(100vh - 34.16px);
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 184px;
  position: sticky;
  top: 0;
  left: 0;
`

export const MenuItem = styled(NavLink)`
  color: ${props => props.theme.SideBar.Menu.textColor};
  display: block;
  padding: 5px 10px;

  &:hover,
  &:focus {
    color: ${props => props.theme.SideBar.Menu.textColor};
    background-color: ${props => props.theme.SideBar.Menu.hoverBackgroundColor};
    text-decoration: none;
    cursor: pointer;
  }

  &.active {
    background-color: ${props => props.theme.SideBar.Menu.activeBackgroundColor};
    text-decoration: none;
  }
`
const renderMenuItems = (userProfile: User, shopfrontEnabled?: boolean, ebayEnabled?: boolean) => (
  <>
    <MenuItem to="/profile">
      <Icon icon="user" />
      My Profile
    </MenuItem>

    <MenuItem to="/point-of-sale/new">
      <Icon icon="money-bill-alt" />
      Point of Sale
    </MenuItem>

    <MenuItem to="/customers">
      <Icon icon={['far', 'address-book']} />
      Customers
    </MenuItem>

    <MenuItem to="/products">
      <Icon icon="tshirt" />
      Products
    </MenuItem>

    {(userProfile.kind === 'owner' || userProfile.kind === 'staff_full') && (
      <>
        <MenuItem to="/mobile">
          <Icon icon="mobile" />
          Mobile
        </MenuItem>

        {shopfrontEnabled && (
          <MenuItem to="/shopfront">
            <Icon icon="shopping-cart" />
            Shopfront
          </MenuItem>
        )}
      </>
    )}

    {ebayEnabled && (
      <>
        <MenuItem to="/ebay-packing-slips">
          <Icon icon="file-import" />
          eBay Packing Slips
        </MenuItem>
      </>
    )}

    <MenuItem to="/order-processing">
      <Icon icon="tasks" />
      Order Processing
    </MenuItem>

    <MenuItem to="/manifests">
      <Icon icon="truck" />
      Manifests
    </MenuItem>

    <MenuItem to="/order-history">
      <Icon icon="history" />
      Order History
    </MenuItem>

    <MenuItem to="/order-tracking">
      <Icon icon="truck" />
      Order Tracking
    </MenuItem>

    {userProfile.kind === 'owner' && (
      <MenuItem to="/users">
        <Icon icon="users" />
        Users
      </MenuItem>
    )}

    <MenuItem to="/reports">
      <Icon icon="chart-simple" />
      Reports
    </MenuItem>

    <MenuItem to="/settings">
      <Icon icon="cogs" />
      Settings
    </MenuItem>
  </>
)

const SideMenu = () => {
  const { userProfile } = useAuth()
  const shopfrontEnabled = userProfile?.accountSettings?.shopfrontEnabled
  const ebayEnabled = userProfile?.accountSettings?.ebayEnabled
  return (
    <MenuWrapper>
      <Menu>
        <MenuItem to="/">
          <Icon icon="home" />
          Home
        </MenuItem>

        {userProfile && renderMenuItems(userProfile, shopfrontEnabled, ebayEnabled)}

        <LogoutMenuItem />
      </Menu>
    </MenuWrapper>
  )
}

export default SideMenu

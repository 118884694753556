import { DenormalizedInventoryItem, GroupBy } from './types'
import { SortableHeader, SortDirection } from './TableComponents'
import { formatCurrency } from '~/lib/format-utils'
import { tableClasses } from './table-classes'

interface InventoryTableHeaderProps {
  groupBy: GroupBy
  sortField: keyof DenormalizedInventoryItem | null
  sortDirection: SortDirection
  onSort: (field: keyof DenormalizedInventoryItem | null) => void
  totalCost: number
  totalCurrentValue: number
  groupTitle?: React.ReactNode
}

const InventoryTableHeader: React.FC<InventoryTableHeaderProps> = ({
  groupBy,
  sortField,
  sortDirection,
  onSort,
  totalCost,
  totalCurrentValue,
  groupTitle
}) => {
  // Calculate the colspan for the group title
  const getGroupTitleColspan = () => {
    // Count columns based on which are shown
    let colCount = 8 // Base columns (date, qty fields, etc)

    // Add columns based on grouping
    if (groupBy !== 'product') colCount += 2 // Product name & status
    if (groupBy !== 'invoice_no') colCount += 1 // Invoice #
    if (groupBy !== 'supplier_name') colCount += 1 // Supplier name

    return colCount - 2 // Subtract 2 for the total cols which are separate
  }

  return (
    <>
      {/* Group title and totals row */}
      {groupTitle && (
        <tr className="bg-gray-100 border-b border-gray-200">
          <th colSpan={getGroupTitleColspan()} className={`px-4 py-3 text-left text-sm font-medium text-gray-700`}>
            {groupTitle}
          </th>
          <th className={`${tableClasses.headerBase} ${tableClasses.headerRight} text-gray-700`}>
            {formatCurrency(totalCost)}
          </th>
          <th className={`${tableClasses.headerBase} ${tableClasses.headerRight} text-gray-700`}>
            {formatCurrency(totalCurrentValue)}
          </th>
        </tr>
      )}

      {/* Column headers row */}
      <tr>
        {groupBy !== 'product' && (
          <>
            <SortableHeader<DenormalizedInventoryItem>
              field="productName"
              label="Product"
              currentSortField={sortField}
              sortDirection={sortDirection}
              onSort={onSort}
            />
            <SortableHeader<DenormalizedInventoryItem>
              field="productStatus"
              label="Status"
              currentSortField={sortField}
              sortDirection={sortDirection}
              onSort={onSort}
            />
          </>
        )}
        {groupBy !== 'invoice_no' && (
          <SortableHeader<DenormalizedInventoryItem>
            field="invoiceNo"
            label="Invoice #"
            currentSortField={sortField}
            sortDirection={sortDirection}
            onSort={onSort}
          />
        )}
        {groupBy !== 'supplier_name' && (
          <SortableHeader<DenormalizedInventoryItem>
            field="supplierName"
            label="Supplier"
            currentSortField={sortField}
            sortDirection={sortDirection}
            onSort={onSort}
          />
        )}
        <SortableHeader<DenormalizedInventoryItem>
          field="purchaseDate"
          label="Purchase Date"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <SortableHeader<DenormalizedInventoryItem>
          field="shelfLocation"
          label="Shelf Location"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <SortableHeader<DenormalizedInventoryItem>
          field="originalQuantity"
          label="Original Qty"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
          align="right"
        />
        <SortableHeader<DenormalizedInventoryItem>
          field="quantitySoldFifo"
          label="Qty Sold"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
          align="right"
        />
        <SortableHeader<DenormalizedInventoryItem>
          field="currentQuantity"
          label="SOH Qty"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
          align="right"
        />
        <SortableHeader<DenormalizedInventoryItem>
          field="costPricePerUnit"
          label="Cost Per Unit"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
          align="right"
        />
        <SortableHeader<DenormalizedInventoryItem>
          field="totalCost"
          label="Total Cost"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
          align="right"
        />
        <SortableHeader<DenormalizedInventoryItem>
          field="currentValue"
          label="SOH Value"
          currentSortField={sortField}
          sortDirection={sortDirection}
          onSort={onSort}
          align="right"
        />
      </tr>
    </>
  )
}

export default InventoryTableHeader

import { createGlobalStyle } from 'styled-components'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ToastContainer } from 'react-toastify'

import PrivateRoute from '~/components/private-route'
import { AuthProvider } from '~/contexts/auth'
import { AuPostcodesProvider } from '~/contexts/au-postcodes'
import { CountryCodesProvider } from '~/contexts/country-codes'
import { SettingsProvider } from '~/contexts/settings'
import { ConfirmContextProvider } from '~/components/confirm/confirm-context'
import Login from '~/containers/login-page'
import ForgotPasswordPage from '~/containers/forgot-password-page'
import ResetPasswordPage from '~/containers/reset-password-page'
import LoadingView from '~/components/loading-view'
import Layout from '~/components/layout'
import globalStyles from './styles/global-styles'
import './lib/font-awesome-library'
import '~/lib/yup'

import createPage from '~/hoc/create-page'
import { NotFound } from '~/components/error-messages'
import HomePage from '~/containers/home-page'
import OrderProcessingPage from '~/containers/order-processing-page'
import TrackingPage from '~/containers/order-tracking-page'
import CustomersPage from '~/containers/customers-page'
import CustomerFormPage from '~/containers/customer-form-page'
import OrderHistoryPage from '~/containers/order-history-page'
import OrderDetailsFormPage from '~/containers/order-details-form-page'
import PosPage from '~/containers/pos-page'
import ProductsPage from '~/containers/products-page'
import ProductFormPage from '~/containers/product-form-page'
import ImageUploadPage from '~/containers/image-upload-page'
import ProductSearchLinksPage from '~/containers/product-search-links-page'
import ProductSearchLinkFormPage from '~/containers/product-search-link-form-page'
import PriorityItemsPage from '~/containers/priority-items-page'
import MenuItemsPage from '~/containers/menu-items-page'
import SettingsPage from '~/containers/settings/index'
import _UserProfilePage from '~/containers/user-profile-page'
import Users from '~/containers/users'
import EbayPackingSlipsPage from '~/containers/ebay-packing-slips-page'
import ProductsImportPage from '~/containers/products-import-page'
import ManifestsPage from '~/containers/au-post-manifests-page'
import ShipmentsPage from '~/containers/au-post-shipments-page'
import ShipmentFormPage from '~/containers/au-post-shipment-form-page'
import useAuth from '~/contexts/useAuth'
import { PriorityItemKindEnum } from '~/types/priority-item'
import { MenuItemKindEnum } from '~/types/menu-item'
import ReportsPage from '~/containers/reports/index'

const UsersPage = createPage(Users)
const UserProfilePage = createPage(_UserProfilePage)

const EbayRoutes = () => {
  const { userProfile } = useAuth()
  return userProfile?.accountSettings?.ebayEnabled ? <Outlet /> : <Navigate to="/" />
}

const ShopfrontRoutes = () => {
  const { userProfile } = useAuth()
  return userProfile?.accountSettings?.shopfrontEnabled ? <Outlet /> : <Navigate to="/" />
}

const GlobalStyle = createGlobalStyle`${globalStyles}`

const App = () => (
  <AuthProvider>
    <SettingsProvider>
      <AuPostcodesProvider>
        <CountryCodesProvider>
          <DndProvider backend={HTML5Backend}>
            <ConfirmContextProvider>
              <LoadingView />
              <GlobalStyle />

              <Routes>
                <Route path="/signin" element={<Login />} />
                <Route path="/forgot_password" element={<ForgotPasswordPage />} />
                <Route path="/reset_password/:token" element={<ResetPasswordPage />} />

                <Route element={<Layout />}>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route path="/shipments/:shipmentId" element={<ShipmentFormPage />} />
                    <Route path="/manifests/:manifestId" element={<ShipmentsPage />} />
                    <Route path="/manifests" element={<ManifestsPage />} />

                    <Route path="/order-processing" element={<OrderProcessingPage />} />
                    <Route path="/order-tracking" element={<TrackingPage />} />

                    <Route path="/order-history" element={<OrderHistoryPage />} />
                    <Route path="/orders/:id/edit" element={<OrderDetailsFormPage />} />

                    <Route path="/products/import" element={<ProductsImportPage />} />
                    <Route path="/products/new" element={<ProductFormPage />} />
                    <Route path="/products/bulk-photos" element={<ImageUploadPage />} />
                    <Route path="/products/:productId/edit" element={<ProductFormPage />} />
                    <Route path="/products/:productId/edit#update-inventory" element={<ProductFormPage />} />
                    <Route path="/products" element={<ProductsPage />} />

                    <Route path="/customers/new" element={<CustomerFormPage />} />
                    <Route path="/customers/:id/edit" element={<CustomerFormPage />} />
                    <Route path="/customers" element={<CustomersPage />} />

                    <Route path="/point-of-sale/new" element={<PosPage />} />
                    <Route path="/point-of-sale/:id/edit" element={<PosPage />} />

                    <Route path="/mobile/product-search-links/new" element={<ProductSearchLinkFormPage isNew />} />
                    <Route path="/mobile/product-search-links/:id/edit" element={<ProductSearchLinkFormPage />} />
                    <Route path="/mobile/product-search-links" element={<ProductSearchLinksPage />} />
                    <Route path="/mobile" element={<PriorityItemsPage kind={PriorityItemKindEnum.MOBILE} />} />

                    <Route element={<ShopfrontRoutes />}>
                      <Route path="/shopfront/product-search-links/new" element={<ProductSearchLinkFormPage isNew />} />
                      <Route path="/shopfront/product-search-links/:id/edit" element={<ProductSearchLinkFormPage />} />
                      <Route path="/shopfront/product-search-links" element={<ProductSearchLinksPage />} />
                      <Route
                        path="/shopfront/menu-items"
                        element={<MenuItemsPage kind={MenuItemKindEnum.SHOPFRONT} />}
                      />
                      <Route path="/shopfront" element={<PriorityItemsPage kind={PriorityItemKindEnum.SHOPFRONT} />} />
                    </Route>

                    <Route element={<EbayRoutes />}>
                      <Route path="/ebay-packing-slips" element={<EbayPackingSlipsPage />} />
                    </Route>

                    <Route path="/settings/*" element={<SettingsPage />} />
                    <Route path="/users/*" element={<UsersPage />} />

                    <Route path="/profile" element={<UserProfilePage />} />

                    <Route path="/reports/*" element={<ReportsPage />} />

                    <Route path="/" element={<HomePage />} />

                    {/* 404 route must come last */}
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Route>
              </Routes>
              <ToastContainer />
            </ConfirmContextProvider>
          </DndProvider>
        </CountryCodesProvider>
      </AuPostcodesProvider>
    </SettingsProvider>
  </AuthProvider>
)

export default App

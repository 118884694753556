// Sort directions
export type SortDirection = 'asc' | 'desc' | null

// Sort state
export interface SortState {
  field: string
  direction: SortDirection
}

// Sort items by field and direction
export function sortItems<T>(items: T[], sortState: SortState): T[] {
  const { field, direction } = sortState

  if (!field || !direction) {
    return items
  }

  return [...items].sort((a, b) => {
    const aValue = a[field as keyof T]
    const bValue = b[field as keyof T]

    // Special case for handling null or empty supplier names - always sort these to the top
    if (field === 'supplier') {
      const aIsEmpty = aValue === null || aValue === undefined || aValue === ''
      const bIsEmpty = bValue === null || bValue === undefined || bValue === ''

      // If both are empty or null, consider them equal
      if (aIsEmpty && bIsEmpty) return 0

      // Always put empty/null values at the top regardless of sort direction
      if (aIsEmpty) return -1
      if (bIsEmpty) return 1
    }

    // Special case for money values stored as strings (like "123.45")
    if (field === 'totalStockValue' || field.includes('price') || field.includes('value') || field.includes('cost')) {
      // Convert string money values to numbers for proper numeric sorting
      const aNum = typeof aValue === 'string' ? parseFloat(aValue) : Number(aValue)
      const bNum = typeof bValue === 'string' ? parseFloat(bValue) : Number(bValue)

      // Handle NaN values
      if (isNaN(aNum) && isNaN(bNum)) return 0
      if (isNaN(aNum)) return direction === 'asc' ? -1 : 1
      if (isNaN(bNum)) return direction === 'asc' ? 1 : -1

      return direction === 'asc' ? aNum - bNum : bNum - aNum
    }

    // Handle different data types
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return direction === 'asc' ? aValue - bValue : bValue - aValue
    }

    // Convert to strings for comparison if types don't match
    const aString = String(aValue)
    const bString = String(bValue)

    return direction === 'asc' ? aString.localeCompare(bString) : bString.localeCompare(aString)
  })
}

// Toggle sort state with cycling behavior (asc → desc → null)
export function toggleSort(field: string, currentSort: SortState): SortState {
  if (currentSort.field !== field) {
    return { field, direction: 'asc' }
  }

  const currentDirection = currentSort.direction

  if (currentDirection === 'asc') {
    return { field, direction: 'desc' }
  }

  if (currentDirection === 'desc') {
    return { field: '', direction: null }
  }

  return { field, direction: 'asc' }
}

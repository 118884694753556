import { ComponentType } from 'react'
import styled from 'styled-components'
import { Field, FieldProps, FieldAttributes } from 'formik'

import _ from '~/lib/lodash'
import camelToSnakeCase from '~/lib/camel-to-snake-case'
import RegularInput from '~/components/form-inputs/formik/input-types/regular'
import CheckboxInput from '~/components/form-inputs/formik/input-types/checkbox'
import TextareaInput from '~/components/form-inputs/formik/input-types/textarea'
import SelectInput from '~/components/form-inputs/formik/input-types/select'
import DateInput from '~/components/form-inputs/formik/input-types/date'
import MarkdownInput from '~/components/form-inputs/formik/input-types/markdown'
import RadioGroupInput from '~/components/form-inputs/formik/input-types/radio-group'

const StyledSelectInput = styled(SelectInput)`
  flex: 1;
`
const Hint = styled.div`
  color: ${props => props.theme.colors.gray.light};
  font-size: 0.8rem;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface WrapperProps {
  marginBottom?: string
}
const Wrapper = styled.div<WrapperProps>`
  margin-bottom: ${props => props.marginBottom};
  flex: 1;

  & label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }

  & .error {
    color: red;
    margin-top: 0.25rem;
  }
`

export interface Option {
  label: string
  value: string
}

type FieldType = 'date' | 'select' | 'radioGroup' | 'textarea' | 'markdown' | 'password' | 'text' | 'checkbox'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _inputComponentName = (type?: FieldType): ComponentType<any> => {
  switch (type) {
    case 'date':
      return DateInput
    case 'select':
      return StyledSelectInput
    case 'radioGroup':
      return RadioGroupInput
    case 'textarea':
      return TextareaInput
    case 'markdown':
      return MarkdownInput
    case 'checkbox':
      return CheckboxInput
    default:
      return RegularInput
  }
}

interface InputGroupOwnProps {
  className?: string
  labelClassName?: string
  name?: string
  placeholder?: string
  label?: string | boolean
  hint?: string
  marginBottom?: string
  options?: Option[]
  type?: FieldType
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputGroupComponentProps<V = any, FormValues = any> = InputGroupOwnProps & FieldProps<V, FormValues>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InputGroupComponent = <V = any, FormValues = any>({
  className,
  labelClassName,
  label,
  field,
  form,
  marginBottom = '20px',
  type = 'text',
  hint,
  options,
  ...otherProps
}: InputGroupComponentProps<V, FormValues>) => {
  const id = `field_${camelToSnakeCase(field.name)}`
  const key = field.name as keyof FormValues

  const Component = _inputComponentName(type)
  const componentClassName = form.errors[key] && form.touched[key] ? 'hasError' : ''

  return (
    <Wrapper className={className} {...{ marginBottom }}>
      {label !== '' && label !== false && (
        <label htmlFor={id} className={labelClassName}>
          {label || _.startCase(field.name)}
        </label>
      )}

      <InputWrapper>
        <Component
          className={componentClassName}
          field={field}
          form={form}
          id={id}
          type={type}
          value={field.value || ''}
          options={options as Option[]}
          {...otherProps}
        />
        {hint && <Hint>{hint}</Hint>}

        {form.errors[key] && form.touched[key] ? <div className="error">{form.errors[key]?.toString()}</div> : null}
      </InputWrapper>
    </Wrapper>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InputGroupProps<V = any> = FieldAttributes<V> & InputGroupOwnProps
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InputGroup = <V = any,>(props: InputGroupProps<V>) => <Field {...props} component={InputGroupComponent} />

export default InputGroup

import { Link } from 'react-router-dom'
import { useFormikContext } from 'formik'

import Icon from '~/components/icon'
import { OrderFormValues } from './use-order-details'

const PackageContents = () => {
  const { values: formValues } = useFormikContext<OrderFormValues>()
  const { lineItems } = formValues

  return (
    <fieldset>
      <legend>Package Contents</legend>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Qty</th>
            <th>Item</th>
            <th>SKU</th>
            <th>Shelf Location</th>
            <th className="text-end">L</th>
            <th className="text-end">W</th>
            <th className="text-end">H</th>
            <th className="text-end">Kg</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {lineItems &&
            lineItems.map(lineItem => (
              <tr key={lineItem.id}>
                <td>{lineItem.quantity}</td>
                <td className={lineItem.product ? '' : 'text-danger'}>
                  {lineItem.product
                    ? lineItem.product.name
                    : lineItem?.importedScancode ?? lineItem.salesRecord?.itemTitle}
                </td>
                <td>{lineItem.product ? lineItem.product.customSku : '-'}</td>
                <td>{lineItem.product ? lineItem.product.currentShelfLocation : '-'}</td>
                <td className="text-end">{lineItem.product ? lineItem.product.packageLength : '-'}</td>
                <td className="text-end">{lineItem.product ? lineItem.product.packageWidth : '-'}</td>
                <td className="text-end">{lineItem.product ? lineItem.product.packageHeight : '-'}</td>
                <td className="text-end">{lineItem.product ? lineItem.product.packageWeight : '-'}</td>
                <td className="text-end">
                  {lineItem.product && (
                    <Link to={`/products/${lineItem.product.id}/edit`} target="_blank">
                      <Icon icon="external-link-alt" />
                    </Link>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </fieldset>
  )
}

export default PackageContents

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import { fetchUnprocessedOrders, fetchOrdersByIds } from '~/async-actions/order-processing-async-actions'
import CsvImport from '~/types/csv-import'
import Order from '~/types/order'

export interface ListMeta {
  currentPage: number
  totalPages: number
  totalCount: number
  pageSize: number
  search: string | null
  categories: string | null
}

export interface OrderProcessingState {
  lastCsvImport?: CsvImport
  fileUploading: boolean
  loading: {
    fetchUnprocessedOrders: boolean
    fetchOrdersByIds: boolean
    bulkUpdateOrders: boolean
  }
  error: {
    fetchUnprocessedOrders: string | null
    fetchOrdersByIds: string | null
  }
  list: Order[]
  listMeta?: ListMeta
  currentOrderToProcess?: Order
}

const initialState: OrderProcessingState = {
  lastCsvImport: undefined,
  fileUploading: false,
  loading: {
    fetchUnprocessedOrders: false,
    fetchOrdersByIds: false,
    bulkUpdateOrders: false
  },
  error: {
    fetchUnprocessedOrders: null,
    fetchOrdersByIds: null
  },
  list: [],
  listMeta: undefined,
  currentOrderToProcess: undefined
}

const sortOrders = (orders: Order[]): Order[] => {
  return _.orderBy(orders, 'id', 'desc')
}

const orderProcessingSlice = createSlice({
  name: 'orderProcessing',
  initialState,
  reducers: {
    setLastCsvImport: (state, action: PayloadAction<CsvImport | undefined>) => {
      state.lastCsvImport = action.payload
    },
    setFileUploading: (state, action: PayloadAction<boolean>) => {
      state.fileUploading = action.payload
    },
    loadOrders: (state, action: PayloadAction<{ items: Order[]; meta: ListMeta }>) => {
      state.list = action.payload.items
      state.listMeta = action.payload.meta
    },
    goToNextProcessingOrder: state => {
      const unprocessed = _.filter(state.list, ['status', 'unprocessed'])
      state.currentOrderToProcess = unprocessed[0] || null
    },
    clearProcessingOrder: state => {
      state.currentOrderToProcess = undefined
    },
    updateProcessingOrder: (state, action: PayloadAction<Order>) => {
      const newList = state.list.filter(order => order.id !== action.payload.id)
      newList.push(action.payload)
      state.list = sortOrders(newList)
    },
    resetProcessingOrders: state => {
      state = initialState
      return state // just to get eslint happy
    }
  },
  extraReducers: builder => {
    builder
      // fetchUnprocessedOrders async action
      .addCase(fetchUnprocessedOrders.pending, state => {
        state.loading.fetchUnprocessedOrders = true
        state.error.fetchUnprocessedOrders = null
      })
      .addCase(fetchUnprocessedOrders.fulfilled, (state, action) => {
        state.list = sortOrders(action.payload.items)
        state.listMeta = action.payload.meta
        state.loading.fetchUnprocessedOrders = false
      })
      .addCase(fetchUnprocessedOrders.rejected, (state, action) => {
        state.loading.fetchUnprocessedOrders = false
        state.error.fetchUnprocessedOrders = action.payload?.message || 'Error fetching orders'
      })
      // Track the bulk update loading state (imported from orders-async-actions)
      .addCase('orders/bulkUpdateOrders/pending', state => {
        // Add property to track bulk update loading in the order processing reducer
        state.loading['bulkUpdateOrders'] = true
      })
      .addCase('orders/bulkUpdateOrders/fulfilled', state => {
        state.loading['bulkUpdateOrders'] = false
      })
      .addCase('orders/bulkUpdateOrders/rejected', state => {
        state.loading['bulkUpdateOrders'] = false
      })

      // fetchOrdersByIds async action
      .addCase(fetchOrdersByIds.pending, state => {
        state.loading.fetchOrdersByIds = true
        state.error.fetchOrdersByIds = null
      })
      .addCase(fetchOrdersByIds.fulfilled, (state, action) => {
        // Update only the orders that were fetched, keeping other orders in the list
        const fetchedOrderIds = action.payload.items.map(order => order.id)
        const remainingOrders = state.list.filter(order => !fetchedOrderIds.includes(order.id))
        state.list = sortOrders([...remainingOrders, ...action.payload.items])
        state.loading.fetchOrdersByIds = false
      })
      .addCase(fetchOrdersByIds.rejected, (state, action) => {
        state.loading.fetchOrdersByIds = false
        state.error.fetchOrdersByIds = action.payload?.message || 'Error fetching orders by IDs'
      })
  }
})

export const {
  setLastCsvImport,
  setFileUploading,
  loadOrders,
  goToNextProcessingOrder,
  clearProcessingOrder,
  updateProcessingOrder,
  resetProcessingOrders
} = orderProcessingSlice.actions

export default orderProcessingSlice.reducer

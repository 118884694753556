import { useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/forms'
import ExportButton from '~/components/export-button'
import ShipmentCreatorModal from '~/components/shipment-creator-modal'
import Order, { OrderStatusEnum } from '~/types/order'
import { bulkUpdateOrders, fetchOrders } from '~/async-actions/orders-async-actions'
import { AppDispatch } from '~/config/store'
import { useConfirm } from '~/components/confirm/use-confirm'

const shipmentBtnStyles = {
  marginRight: '10px'
}

interface OrderActionsProps {
  selectedIds: number[]
  ordersList?: Order[]
  /**
   * Optional callback to refresh orders after bulk update
   * If not provided, defaults to fetchOrders({})
   */
  refreshCallback?: (orderIds: number[]) => Promise<unknown>
  /**
   * Optional loading state for bulk update from parent component
   * This allows the component to reflect the loading state managed in the parent's reducer
   */
  isExternalBulkUpdateLoading?: boolean
}

const OrderActions = ({
  selectedIds,
  ordersList,
  refreshCallback,
  isExternalBulkUpdateLoading = false
}: OrderActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [showConfirm] = useConfirm()
  const [showShipmentModal, setShowShipmentModal] = useState(false)
  const [isMarkingAsPicked, setIsMarkingAsPicked] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const unprocessedList = ordersList?.filter((o: Order) => o.status === 'unprocessed')
  const hasUnprocessed = unprocessedList?.filter((o: Order) => selectedIds.includes(o.id))?.length ?? 0 > 0
  const disableCreateShipment = selectedIds.length === 0
  const isAnyUpdateLoading = isMarkingAsPicked || isExternalBulkUpdateLoading
  const isAnyOperation = isAnyUpdateLoading || isRefreshing
  const disableActions = isAnyOperation || selectedIds.length === 0
  const disableExportButtons = !selectedIds.length || isAnyOperation

  const handleClickCreateShipment = () => {
    if (hasUnprocessed) {
      const alertMsg =
        'There are unprocessed orders among the selected orders. Please uncheck them or convert them to processed orders'
      toast.error(alertMsg)
      return
    }
    setShowShipmentModal(true)
  }

  const performMarkAsPicked = async () => {
    if (selectedIds.length === 0) {
      return
    }

    setIsMarkingAsPicked(true)

    try {
      await dispatch(
        bulkUpdateOrders({
          orderIds: selectedIds,
          update: { status: OrderStatusEnum.PICKED }
        })
      ).unwrap()

      // Refresh the orders list to update the UI using the provided callback or default to fetchOrders
      setIsRefreshing(true)
      try {
        if (refreshCallback) {
          await refreshCallback(selectedIds)
        } else {
          await dispatch(fetchOrders({ order_ids: selectedIds }))
        }
      } finally {
        setIsRefreshing(false)
      }

      toast.success(`Successfully marked ${selectedIds.length} order(s) as picked`)
    } catch (error) {
      toast.error('Failed to mark orders as picked')
      console.error(error)
    } finally {
      setIsMarkingAsPicked(false)
    }
  }

  const handleMarkAsPicked = async () => {
    const orderCount = selectedIds.length
    if (orderCount === 0) {
      return
    }

    const confirmed = await showConfirm({
      title: 'Mark Orders as Picked',
      message: `Are you sure you want to mark ${orderCount} order${orderCount > 1 ? 's' : ''} as picked?`,
      kind: 'warning'
    })

    if (confirmed) {
      await performMarkAsPicked()
    }
  }

  return (
    <>
      <ShipmentCreatorModal
        orderIds={selectedIds}
        show={showShipmentModal}
        onHide={() => setShowShipmentModal(false)}
      />

      <div>
        <Button
          variant="info"
          style={shipmentBtnStyles}
          disabled={disableCreateShipment || isAnyOperation}
          onClick={handleClickCreateShipment}>
          Create Shipment
        </Button>
        <ExportButton
          disabled={disableExportButtons}
          endpoint={`/api/orders/export?report=picklist-pdf&order_ids=${selectedIds.join(',')}`}
          label="Export Pick List (PDF)"
        />{' '}
        &nbsp;&nbsp;
        <Button
          variant="success"
          disabled={disableActions}
          onClick={handleMarkAsPicked}
          isSubmitting={isMarkingAsPicked || isExternalBulkUpdateLoading || isRefreshing}
          isSubmittingLabel={isRefreshing ? 'Refreshing...' : 'Marking as Picked...'}>
          Mark as Picked
        </Button>{' '}
        &nbsp;&nbsp;
        <ExportButton
          disabled={disableExportButtons}
          endpoint={`/api/orders/export?report=eparcels-csv&order_ids=${selectedIds.join(',')}`}
          label="Export for eParcels (CSV)"
        />{' '}
        &nbsp;&nbsp;
        <ExportButton
          disabled={disableExportButtons}
          endpoint={`/api/orders/export?report=parcel-send-csv&order_ids=${selectedIds.join(',')}`}
          label="Export for Parcel Send (CSV)"
        />{' '}
        &nbsp;&nbsp;
        <ExportButton
          disabled={disableExportButtons}
          endpoint={`/api/orders/export?report=labels-pdf&order_ids=${selectedIds.join(',')}`}
          label="Print Labels (PDF)"
        />{' '}
        &nbsp;&nbsp;
        <Button variant="info" disabled>
          <strong>Print Invoices</strong>
        </Button>
      </div>
    </>
  )
}

export default OrderActions

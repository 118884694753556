import { FormikProvider, FormikProps } from 'formik'

import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import Button from '~/components/forms/button'
import ErrorMessage from './ErrorMessage'
import { InventorySearchValues, GroupBy } from './types'

interface InventorySearchFormProps {
  formik: FormikProps<InventorySearchValues>
  loading: boolean
  error: string | null
  groupBy: GroupBy
  setGroupBy: (groupBy: GroupBy) => void
}

const InventorySearchForm = ({ formik, loading, error, groupBy, setGroupBy }: InventorySearchFormProps) => {
  const { getFieldProps, isSubmitting, handleSubmit } = formik

  const statusOptions = [
    { value: '', label: 'All Products' },
    { value: 'active', label: 'Active Products' },
    { value: 'inactive', label: 'Inactive Products' }
  ]

  const groupByOptions = [
    { value: 'product', label: 'Group by Product' },
    { value: 'invoice_no', label: 'Group by Invoice Number' },
    { value: 'supplier_name', label: 'Group by Supplier' }
  ]

  return (
    <div className="bg-gray-50 p-4 rounded-lg shadow-md mb-8">
      <h2 className="text-base font-bold text-gray-800 mb-4">Search by Invoice or Supplier</h2>

      <FormikProvider value={formik}>
        <div className="space-y-4">
          <div className="flex flex-col">
            <BaseErrors />

            <div className="flex items-center space-x-4">
              <FlexibleInputGroup
                label="Invoice Number"
                type="text"
                {...getFieldProps('invoiceNo')}
                placeholder="Enter invoice number"
                className="max-w-lg"
                labelClassName="min-w-auto"
              />

              <FlexibleInputGroup
                label="Supplier Name"
                type="text"
                {...getFieldProps('supplierName')}
                placeholder="Enter supplier name"
                className="max-w-lg"
                labelClassName="min-w-auto"
              />

              <FlexibleInputGroup
                label="Product Status"
                type="select"
                {...getFieldProps('status')}
                placeholder="All Products"
                options={statusOptions}
                className="max-w-lg"
                labelClassName="min-w-auto"
              />
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="max-w-xs">
              <label htmlFor="groupBy" className="block text-sm font-medium text-gray-700 mb-1">
                Group Results By
              </label>
              <select
                id="groupBy"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={groupBy}
                onChange={e => setGroupBy(e.target.value as GroupBy)}>
                {groupByOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <Button
              onClick={() => handleSubmit()}
              variant="primary"
              disabled={isSubmitting || loading}
              isSubmitting={isSubmitting || loading}
              isSubmittingLabel="Searching...">
              Search
            </Button>
          </div>
        </div>
      </FormikProvider>

      <ErrorMessage message={error} />
    </div>
  )
}

export default InventorySearchForm

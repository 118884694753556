import { SortDirection } from './TableComponents'

/**
 * Helper for sorting items based on a field and direction
 */
export function sortItems<T>(items: T[], sortField: keyof T | null, sortDirection: SortDirection): T[] {
  if (!sortField || !sortDirection) return items

  return [...items].sort((a, b) => {
    const aValue = a[sortField]
    const bValue = b[sortField]

    // Handle different data types for proper sorting
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      // String comparison
      const comparison = aValue.localeCompare(bValue)
      return sortDirection === 'asc' ? comparison : -comparison
    } else if (sortField === 'purchaseDate') {
      // Date comparison
      const dateA = new Date(String(aValue)).getTime()
      const dateB = new Date(String(bValue)).getTime()
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA
    } else {
      // Number comparison (handle money values as numbers)
      const numA = typeof aValue === 'number' ? aValue : parseFloat(String(aValue) || '0')
      const numB = typeof bValue === 'number' ? bValue : parseFloat(String(bValue) || '0')
      return sortDirection === 'asc' ? numA - numB : numB - numA
    }
  })
}

/**
 * Toggle sort direction for a field
 */
export function toggleSort<T>(
  field: keyof T,
  currentField: keyof T | null,
  currentDirection: SortDirection,
  setSortField: (field: keyof T | null) => void,
  setSortDirection: (direction: SortDirection) => void
): void {
  // If clicking the same field, cycle through: asc -> desc -> null
  if (field === currentField) {
    if (currentDirection === 'asc') {
      setSortDirection('desc')
    } else if (currentDirection === 'desc') {
      setSortDirection(null)
      setSortField(null)
    } else {
      setSortDirection('asc')
    }
  } else {
    // New field selected, start with asc
    setSortField(field)
    setSortDirection('asc')
  }
}

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import useAuth from '~/contexts/useAuth'
import Icon from '~/components/icon'
import sections from './sections'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.Body.textColor};
  text-decoration: none !important;
  cursor: pointer;
`

const Button = styled.div`
  width: 128px;
  height: 100px;
  text-align: center;
  padding: 15px;
  margin-bottom: 10px;
`

const IconsView = () => {
  const { userProfile } = useAuth()
  return (
    <Wrapper>
      {userProfile &&
        sections
          .filter(s => s.userKind === undefined || s.userKind.includes(userProfile.kind))
          .map(s => (
            <StyledLink key={s.path} to={`/reports${s.path}`}>
              <Button>
                {s.icon && <Icon icon={s.icon as IconProp} size="2x" />}
                <p>{s.label}</p>
              </Button>
            </StyledLink>
          ))}
    </Wrapper>
  )
}

export default IconsView

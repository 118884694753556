interface ErrorMessageProps {
  message: string | null
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  if (!message) return null

  return <div className="mt-4 p-3 bg-red-50 text-red-700 border-l-4 border-red-500 rounded">{message}</div>
}

export default ErrorMessage

import { ChangeEvent, useState, useEffect, useMemo, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'
import _ from 'lodash'
import ImportIdPicker from './import-id-picker'
import DateRangePicker from './date-range-picker'
import classnames from 'classnames'

enum CreatedWithEnum {
  POS = 'pos',
  ALL = 'all',
  EBAY_IMPORT = 'ebay_import',
  GENERIC_IMPORT = 'generic_import',
  MOBILE = 'mobile',
  SHOPFRONT = 'shopfront'
}

enum RecordTypeEnum {
  ALL = 'all',
  DOMESTIC = 'domestic',
  INTERNATIONAL = 'international'
}

export enum OrderStatusEnum {
  ALL = 'all',
  UNPROCESSED = 'unprocessed',
  PROCESSED = 'processed',
  PICKED = 'picked'
}

export interface FilterChangeParams {
  customer?: string
  product?: string
  createdAt?: string
  createdWith?: CreatedWithEnum
  recordType?: RecordTypeEnum
  status?: OrderStatusEnum
}

type CreatedAt = string | 'any' | 'today' | 'last_import'

function isDateRange(value: string) {
  return _.startsWith(value, 'dates:')
}

function isImportId(value: string) {
  return _.startsWith(value, 'importId:')
}

const Wrapper = styled.div`
  .heading {
    width: 120px;
    text-align: right;
    font-weight: bold;
    margin-right: 10px;
  }
`

interface FiltersProps {
  className?: string
  query: {
    createdWith?: string
    recordType?: string
    status?: string
    createdAt?: string
    customer?: string
    product?: string
  }
  onChange: (params: FilterChangeParams) => void
}

const createdWithList = [
  { value: CreatedWithEnum.ALL, label: 'All' },
  { value: CreatedWithEnum.POS, label: 'Point of Sale' },
  { value: CreatedWithEnum.EBAY_IMPORT, label: 'eBay Import' },
  { value: CreatedWithEnum.GENERIC_IMPORT, label: 'Generic Import' },
  { value: CreatedWithEnum.MOBILE, label: 'Mobile' },
  { value: CreatedWithEnum.SHOPFRONT, label: 'Shopfront' }
]

const locationList = [
  { value: RecordTypeEnum.ALL, label: 'All' },
  { value: RecordTypeEnum.DOMESTIC, label: 'Domestic' },
  { value: RecordTypeEnum.INTERNATIONAL, label: 'International' }
]

const statusList = [
  { value: OrderStatusEnum.ALL, label: 'All' },
  { value: OrderStatusEnum.UNPROCESSED, label: 'Unprocessed' },
  { value: OrderStatusEnum.PROCESSED, label: 'Processed' },
  { value: OrderStatusEnum.PICKED, label: 'Picked' }
]

const Filters = ({ className, query, onChange }: FiltersProps) => {
  const defaults = useMemo(
    () => ({
      createdWith: CreatedWithEnum.ALL,
      recordType: RecordTypeEnum.ALL,
      status: OrderStatusEnum.ALL,
      createdAt: 'any'
    }),
    []
  )
  const queryWithDefaults = useMemo(() => ({ ...defaults, ...query }), [defaults, query])

  const [customerFilter, setCustomerFilter] = useState(queryWithDefaults.customer || '')
  const [productFilter, setProductFilter] = useState(queryWithDefaults.product || '')
  const [createdAt, setCreatedAt] = useState(queryWithDefaults.createdAt || '')

  const debouncedCustomerFilterChange = useDebouncedCallback(
    useCallback(() => {
      onChange({ customer: customerFilter })
    }, [customerFilter, onChange]),
    500
  )

  const debouncedProductFilterChange = useDebouncedCallback(
    useCallback(() => {
      onChange({ product: productFilter })
    }, [productFilter, onChange]),
    500
  )

  useEffect(() => {
    setCustomerFilter(queryWithDefaults.customer || '')
    setProductFilter(queryWithDefaults.product || '')
    setCreatedAt(queryWithDefaults.createdAt || '')
  }, [queryWithDefaults])

  const handleCustomerFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCustomerFilter(e.target.value)
      debouncedCustomerFilterChange()
    },
    [debouncedCustomerFilterChange]
  )

  const handleProductFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setProductFilter(e.target.value)
      debouncedProductFilterChange()
    },
    [debouncedProductFilterChange]
  )

  const handleCreatedAtChange = useCallback(
    (params: { createdAt: CreatedAt }) => {
      const { createdAt } = params

      setCreatedAt(createdAt)

      if (createdAt !== 'importId:_' && createdAt !== 'dates:_') {
        onChange({ createdAt })
      }
    },
    [onChange]
  )

  const renderRadioButton = useCallback(
    (field: keyof FilterChangeParams, value: string, label: string) => {
      const id = `filter-${field}-${value}`
      return (
        <div className="form-check form-check-inline" key={value}>
          <label className="form-check-label" htmlFor={id}>
            {label}
          </label>
          <input
            className="form-check-input"
            type="radio"
            checked={queryWithDefaults[field] === value}
            onChange={() => onChange({ [field]: value })}
            id={id}
          />
        </div>
      )
    },
    [onChange, queryWithDefaults]
  )

  return (
    <Wrapper className={classnames('card', className)}>
      <div className="card-body">
        <div className="row mt-2">
          <div className="col-sm-12">
            <label className="heading">Created with:</label>
            {createdWithList.map(({ value, label }) => renderRadioButton('createdWith', value, label))}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-sm-12">
            <label className="heading">Location:</label>
            {locationList.map(({ value, label }) => renderRadioButton('recordType', value, label))}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-sm-12">
            <label className="radio-inline heading">Status:</label>
            {statusList.map(({ value, label }) => renderRadioButton('status', value, label))}
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-sm-12">
            <label className="heading">Created:</label>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                checked={createdAt === 'any'}
                onChange={() => handleCreatedAtChange({ createdAt: 'any' })}
                className="form-check-input"
                id="filter-createdAt-any"
              />
              <label className="form-check-label" htmlFor="filter-createdAt-any">
                Any
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                type="radio"
                checked={createdAt === 'today'}
                onChange={() => handleCreatedAtChange({ createdAt: 'today' })}
                className="form-check-input"
                id="filter-createdAt-today"
              />
              <label className="form-check-label" htmlFor="filter-createdAt-today">
                Today
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                type="radio"
                checked={isDateRange(createdAt)}
                onChange={() => handleCreatedAtChange({ createdAt: 'dates:_' })}
                className="form-check-input"
                id="filter-createdAt-dates"
              />
              <label className="form-check-label" htmlFor="filter-createdAt-dates">
                Within Date Range
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                type="radio"
                checked={createdAt === 'last_import'}
                onChange={() => handleCreatedAtChange({ createdAt: 'last_import' })}
                className="form-check-input"
                id="filter-createdAt-lastImport"
              />
              <label className="form-check-label" htmlFor="filter-createdAt-lastImport">
                On Last Import
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                type="radio"
                checked={isImportId(createdAt)}
                onChange={() => handleCreatedAtChange({ createdAt: 'importId:_' })}
                className="form-check-input"
                id="filter-createdAt-importId"
              />
              <label className="form-check-label d-flex" htmlFor="filter-createdAt-importId">
                <span>By Import ID</span>
                {isImportId(createdAt) && <ImportIdPicker onChange={handleCreatedAtChange} />}
              </label>
            </div>
          </div>
        </div>

        {isDateRange(createdAt) && (
          <div className="row mt-2">
            <div className="col-sm-12">
              <label className="heading" htmlFor="filter-createdAt-dateRange">
                Date Range:
              </label>
              <DateRangePicker onChange={handleCreatedAtChange} id="filter-createdAt-dateRange" />
            </div>
          </div>
        )}

        <div className="row mt-2">
          <div className="col-sm-12 d-flex">
            <label className="heading" htmlFor="filter-customer">
              By Customer:
            </label>
            <div className="flex-grow-1">
              <input
                type="text"
                value={customerFilter}
                placeholder="Type to filter orders by a customer's name, email, ebay_user_id"
                className="form-control ms-n2"
                onChange={handleCustomerFilterChange}
                id="filter-customer"
              />
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-sm-12 d-flex">
            <label className="heading" htmlFor="filter-product">
              By Product:
            </label>
            <div className="flex-grow-1">
              <input
                type="text"
                value={productFilter}
                placeholder="Type to filter orders by a product's ID, custom SKU, name, shelf location"
                className="form-control ms-n2"
                onChange={handleProductFilterChange}
                id="filter-product"
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Filters

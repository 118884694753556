import ProductInventory from '~/types/product-inventory'
import ProductImportMatcher from '~/types/product-import-matcher'
import { ImageThumbnail } from '~/types/image'

export enum ProductStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum ProductConditionEnum {
  NEW_WITH_TAGS = 'new_with_tags',
  NEW_WITHOUT_TAGS = 'new_without_tags',
  NEW_WITH_DEFECTS = 'new_with_defects',
  USED = 'used'
}

export enum ProductGenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  UNISEX = 'unisex'
}

export enum ProductAgeGroup {
  NEWBORN = 'newborn',
  INFANT = 'infant',
  TODDLER = 'toddler',
  KIDS = 'kids',
  ADULT = 'adult'
}

export type CustomDataOriginal = { [key: string]: string }
export type CustomDataTransformed = Array<{ key: string; value: string }>

export interface ProductImagePhoto {
  id: number
  grid: ImageThumbnail
}

export interface ImageFile extends File {
  preview: string
}

export interface ProductImage {
  id: number
  photo: ProductImagePhoto
  _file?: ImageFile // only used when saving
  _new: boolean // only used when saving
  _destroy?: boolean // only used when saving
  tags: string[]
  mobile: boolean
  sortIndex: number
  downloadUrl: string
}

export interface DropshipFees {
  act?: number
  nswm?: number
  nswr?: number
  ntm?: number
  ntr?: number
  qldm?: number
  qldr?: number
  remote?: number
  sam?: number
  sar?: number
  tasm?: number
  tasr?: number
  vicm?: number
  vicr?: number
  wam?: number
  war?: number
}

interface Product {
  id?: number
  name: string
  description?: string | null
  seoTitle?: string | null
  seoMetaDescription?: string | null
  thumbnailName?: string | null
  condition: ProductConditionEnum
  sku?: string | null
  customSku: string
  packageLength?: number | null
  packageWidth?: number | null
  packageHeight?: number | null
  packageWeight?: number | null
  currentShelfLocation?: string
  currentShelfLocationStockOnHand?: number
  rrp?: number | null
  packageWeightIntl?: number | null
  barcode?: string | null
  categories: string[]
  brand?: string | null
  model?: string | null
  customsOriginCountryCode?: string | null
  customsTariffNumber?: string | null
  customsDescription?: string | null
  quantityAvailable: number
  onlineQuantityAvailable?: number | null
  otherQuantityAvailable?: number | null
  quantitySoldUnprocessed?: number | null
  status: ProductStatusEnum
  salePrice?: number | null
  images?: ProductImage[]
  featured: boolean
  mobile: boolean
  customData?: CustomDataTransformed
  coverImageUrl?: string | null
  mobileName?: string | null
  mobileNameShort?: string | null
  mobileCheckoutEnabled: boolean
  mobileShowDescription: boolean
  mobileShowSpecifications: boolean
  slug?: string
  showInGoogleProductsFeed: boolean
  googleProductCategory?: string | null
  googleShoppingName?: string | null
  gtin?: string | null
  gender?: ProductGenderEnum | null
  color?: string | null
  ageGroup?: ProductAgeGroup | null
  variantGroup?: string | null
  regularShippingProfileId?: number | null
  expressShippingProfileId?: number | null
  discountShippingProfileId?: number | null
  regularSingleItemWeightBasedShipping: boolean
  expressSingleItemWeightBasedShipping: boolean
  dropshipFees?: DropshipFees
  usesHtmlDescription: boolean
  productImportMatchers?: ProductImportMatcher[]
  inventories?: ProductInventory[]
}

export interface ProductOriginal extends Omit<Product, 'customData'> {
  customData?: CustomDataOriginal
}

export default Product

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Field } from 'formik'
import _ from 'lodash'

import { cn } from '~/lib/utils'
import ReactDatePicker from '~/components/form-inputs/formik/inputs-decorated/react-date-picker'
import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import { BsInput, BsSelectInput, CounterInputField } from '~/components/form-inputs/formik/inputs-decorated'
import { ProductFormValues } from './render-stock-history'
import { ProductInventoryStatusEnum } from '~/types/product-inventory'
import useAuth from '~/contexts/useAuth'

const BaseRow = styled.tr``
const BaseCol = styled.td<{ width?: number | string; textAlign?: string }>`
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : '')}
`
const Row = styled(BaseRow)`
  margin-bottom: 10px;
`

const Col = styled(BaseCol)``

import styles from './render-stock-history-row.module.css'

interface RenderStockHistoryRowProps {
  index: number
  formValues: ProductFormValues
  showTotals: boolean
  readOnly: boolean
  existingRecord: boolean
  onRemove: (index: number) => void
}
const RenderStockHistoryRow = ({
  index,
  onRemove,
  formValues,
  showTotals,
  readOnly,
  existingRecord
}: RenderStockHistoryRowProps) => {
  const { userProfile } = useAuth()

  const inventoryStatusOptions = [
    { value: '', label: 'Please select' },
    { value: ProductInventoryStatusEnum.ON_ORDER, label: 'On Order' },
    { value: ProductInventoryStatusEnum.RECEIVED, label: 'Received' }
  ]

  const isPaidOptions = [
    { value: '', label: 'Please select' },
    { value: true, label: 'Paid' },
    { value: false, label: 'Unpaid' }
  ]

  const thisRecord = formValues?.inventories?.[index]
  const { quantityIn, onlineQuantity } = thisRecord || {}

  const quantitySoldOther = thisRecord?.quantitySoldOther || 0
  const mobileQuantityMax = _.max([(thisRecord?.quantityIn || 0) - quantitySoldOther, 0])
  const inventoryField = `inventories[${index}]`

  const isOwner = userProfile?.kind === 'owner'
  const isSoldOut =
    (thisRecord?.quantityIn || 0) > 0 && (thisRecord?.quantityIn || 0) - (thisRecord?.quantitySoldFifo || 0) === 0

  return (
    <Row>
      <Col>
        <Field
          name={`${inventoryField}.purchaseDate`}
          component={ReactDatePicker}
          label={null}
          readOnly={readOnly && existingRecord}
          dateInputFormat="YYYY-MM-DD"
        />
      </Col>
      <Col>
        <div className="d-flex align-items-center">
          <Field
            name={`${inventoryField}.supplierName`}
            component={BsInput}
            label={null}
            readOnly={readOnly && existingRecord}
          />
          {isOwner && thisRecord?.supplierName && (
            <Link
              to={`/reports/products-for-invoice-or-supplier?supplier_name=${thisRecord?.supplierName}&group_by=supplier_name`}
              className="ms-1">
              <Icon icon="arrow-up-right-from-square" />
            </Link>
          )}
        </div>
      </Col>
      <Col>
        <div className="d-flex align-items-center">
          <Field
            name={`${inventoryField}.invoiceNo`}
            component={BsInput}
            label={null}
            readOnly={readOnly && existingRecord}
          />
          {isOwner && thisRecord?.invoiceNo && (
            <Link
              to={`/reports/products-for-invoice-or-supplier?invoice_no=${thisRecord?.invoiceNo}&group_by=invoice_no`}
              className="ms-1">
              <Icon icon="arrow-up-right-from-square" />
            </Link>
          )}
        </div>
      </Col>
      <Col>
        <Field
          name={`${inventoryField}.quantityIn`}
          component={BsInput}
          label={null}
          readOnly={readOnly && existingRecord}
          className={cn(styles.quantityIn, 'form-control')}
        />
      </Col>
      {formValues.mobile && (
        <Col>
          <Field
            name={`${inventoryField}.onlineQuantity`}
            min={0}
            max={mobileQuantityMax}
            component={CounterInputField}
            label={null}
            readOnly={readOnly && existingRecord}
            className={cn(styles.quantityIn)}
          />
        </Col>
      )}
      {formValues.mobile && (
        <Col>
          <input
            readOnly
            disabled
            name={`${inventoryField}.quantityOther`}
            value={quantityIn != null && onlineQuantity != null ? quantityIn - onlineQuantity : ''}
            className={cn(styles.quantityIn, 'form-control')}
          />
        </Col>
      )}
      <Col>
        <Field
          name={`${inventoryField}.costPricePerUnit`}
          component={BsInput}
          label={null}
          readOnly={readOnly && existingRecord}
          className={cn(styles.costPricePerUnit, 'form-control')}
        />
      </Col>
      <Col width="100">
        <Field
          name={`${inventoryField}.isPaid`}
          label={null}
          component={BsSelectInput}
          options={isPaidOptions}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      <Col width="100">
        <Field
          name={`${inventoryField}.status`}
          label={null}
          component={BsSelectInput}
          options={inventoryStatusOptions}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      <Col width="100">
        <Field
          name={`${inventoryField}.shelfLocation`}
          label={null}
          component={BsInput}
          readOnly={readOnly && existingRecord}
          className={cn({ [styles.shelfLocationSoldOut]: isSoldOut }, 'form-control')}
        />
      </Col>
      {showTotals && (
        <>
          <Col width="40" textAlign="center">
            {thisRecord?.quantitySoldFifo}
          </Col>
          <Col width="40" textAlign="center">
            {(quantityIn || 0) - (thisRecord?.quantitySoldFifo || 0)}
          </Col>
          {/* NOTE: simon doesn't want to see these columns anymore, so just hiding them

          <Col width="40" textAlign="center">
            {(formValues?.inventories?.length || 0) > 1 && <>{thisRecord?.quantitySold}</>}
            {(formValues?.inventories?.length || 0) <= 1 && <>N/A</>}
          </Col>
          */}
        </>
      )}
      {/* NOTE: simon doesn't want to see these columns anymore, so just hiding them

      {showTotals && formValues.mobile && (
        <Col width="40" textAlign="center">
          {thisRecord?.quantitySoldMobile}
        </Col>
      )}
      {showTotals && formValues.mobile && (
        <Col width="40" textAlign="center">
          {quantitySoldOther}
        </Col>
      )}
      */}
      {index >= 1 && !(readOnly && existingRecord) && (
        <Col width="40">
          <Button variant="light" onClick={() => onRemove(index)}>
            <Icon icon="trash" className="text-danger" />
          </Button>
        </Col>
      )}
      {!(index >= 1 && !(readOnly && existingRecord)) && <Col width="40" />}
    </Row>
  )
}

export default RenderStockHistoryRow

import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { ListMeta } from '~/reducers/orders-reducer'
import Order, { OrderStatusEnum } from '~/types/order'

// TODO: type this properly
export interface QueryParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface BulkUpdateOrdersParams {
  orderIds: number[]
  update: {
    status: OrderStatusEnum
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchOrders = createAsyncThunk<{ items: Order[]; meta: ListMeta }, QueryParams, { rejectValue: any }>(
  'orders/fetchOrders',
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/orders', { params: queryParams })
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchOrder = createAsyncThunk<Order, number, { rejectValue: any }>(
  'orders/fetchOrder',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/orders/${id}`)
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const bulkUpdateOrders = createAsyncThunk<void, BulkUpdateOrdersParams, { rejectValue: any }>(
  'orders/bulkUpdateOrders',
  async ({ orderIds, update }, { rejectWithValue }) => {
    try {
      await axios.post('/api/orders/bulk_update', {
        order_ids: orderIds,
        update: update
      })
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

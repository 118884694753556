import { ChangeEvent, useState, useEffect } from 'react'
import styled from 'styled-components'

import Icon from '~/components/icon'

const Box = styled.div`
  display: flex;
  flex-direction: row;
`

const Button = styled.button`
  cursor: pointer;
  border-color: transparent;
  background-color: #c2cad8; // bootstrap border color. TODO: how to get this via a variable?
  outline: none;

  &:hover {
    background-color: #93a1bb; // bootstrap focus border color
  }

  &.left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`

const Input = styled.input`
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  flex-basis: 50px;
  min-width: 0;
`

export interface CounterInputProps {
  id?: string
  min?: number
  max?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  onChange: (value: number) => void
  className?: string
}

const CounterInput = ({ id, min = 1, max = 999, value, onChange, className }: CounterInputProps) => {
  const [localMin, setLocalMin] = useState<number>(min)
  const [localMax, setLocalMax] = useState<number>(max)

  useEffect(() => {
    setLocalMin(min !== null && min !== undefined ? min : 1)
    setLocalMax(max !== null && max !== undefined ? max : 999)

    const currentValue = parseInt(value, 10)
    if (currentValue < min) onChange(min)
    if (currentValue > max) onChange(max)
  }, [min, max, value, onChange])

  const increment = () => {
    const currentValue = parseInt(value || '0', 10)
    if (currentValue < localMax) onChange(currentValue + 1)
  }

  const decrement = () => {
    const currentValue = parseInt(value || '0', 10)
    if (currentValue > localMin) onChange(currentValue - 1)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault()
    const newValue = e.target.value === '' ? localMin : parseInt(e.target.value || '0', 10)
    if (newValue >= localMin && newValue <= localMax) onChange(newValue)
  }

  return (
    <Box className={className}>
      <Button type="button" className="left minusBtn" onClick={decrement}>
        <Icon icon="minus" />
      </Button>
      <Input type="text" className="form-control" value={value} onChange={handleChange} id={id} />
      <Button type="button" className="right plusBtn" onClick={increment}>
        <Icon icon="plus" />
      </Button>
    </Box>
  )
}

export default CounterInput

import Icon from '~/components/icon'

interface SupplierSearchInputProps {
  searchTerm: string
  onSearchChange: (value: string) => void
  placeholder?: string
}

const SupplierSearchInput: React.FC<SupplierSearchInputProps> = ({
  searchTerm,
  onSearchChange,
  placeholder = 'Search suppliers...'
}) => {
  return (
    <div className="flex items-center w-full md:w-72">
      <div className="relative flex-grow">
        <div className="flex items-center border border-gray-300 rounded-md overflow-hidden">
          {/* Search icon */}
          <div className="px-2 py-2">
            <Icon icon="search" className="h-4 w-4 text-gray-500" />
          </div>

          {/* Input field */}
          <input
            type="text"
            value={searchTerm}
            onChange={e => onSearchChange(e.target.value)}
            placeholder={placeholder}
            className="flex-grow py-1 pr-2 border-0 focus:ring-0 focus:outline-none text-sm"
          />

          {/* Clear button */}
          {searchTerm && (
            <button type="button" onClick={() => onSearchChange('')} className="px-2 py-1" aria-label="Clear search">
              <Icon icon="times-circle" className="h-4 w-4 text-gray-400 hover:text-gray-600" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default SupplierSearchInput

import { object, string, number, array, boolean } from 'yup'
import '~/lib/yup'

import AccountSettings from '~/types/account-settings'

export interface CreateProductSchemaProps {
  settings?: AccountSettings
}

const createProductSchema = ({ settings: _settings }: CreateProductSchemaProps) => {
  const ScancodeSchema = object().shape({
    marketplace: string().required(),
    scancode: string().required()
  })

  const InventorySchema = object().shape({
    purchaseDate: string()
      .required()
      .test('is-unique', 'The purchase date must be unique.', function (value) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const allDates = this.from?.[1]?.value?.inventories?.map((item: any) => item.purchaseDate)
        const duplicateDates = allDates?.filter((date: string) => date === value)
        const duplicatesFound = duplicateDates?.length === 1 // There should only be 1 occurrence of each date
        return duplicatesFound
      }),
    quantityIn: number().required().integer(),
    costPricePerUnit: number().required().maxDecimalPlaces(2),
    isPaid: boolean().required(),
    status: string().required(),
    onlineQuantity: number().required().integer(),
    supplierName: string().nullable().notRequired(),
    invoiceNumber: string().nullable().notRequired(),
    shelfLocation: string().required()
  })

  const ProductSchema = object().shape({
    name: string().required(),
    customSku: string().required(),
    condition: string().required(),
    status: string().required(),

    // Nested fields validation
    productImportMatchers: array().of(ScancodeSchema),
    inventories: array().of(InventorySchema),

    // Individual field validations
    //
    packageLength: number()
      .integer()
      .min(0)
      .max(105)
      .nullable()
      .requiredIfDependenciesPresent(['packageWidth', 'packageHeight', 'packageWeight']),
    packageWidth: number()
      .integer()
      .min(0)
      .max(105)
      .nullable()
      .requiredIfDependenciesPresent(['packageLength', 'packageHeight', 'packageWeight']),
    packageHeight: number()
      .integer()
      .min(0)
      .max(105)
      .nullable()
      .requiredIfDependenciesPresent(['packageLength', 'packageWidth', 'packageWeight']),
    packageWeight: number()
      .max(99999.99)
      .maxDecimalPlaces(2)
      .nullable()
      .requiredIfDependenciesPresent(['packageLength', 'packageWidth', 'packageHeight']),
    packageWeightIntl: number()
      .min(0)
      .max(99999.99)
      .maxDecimalPlaces(2)
      .nullable()
      .requiredIfDependenciesPresent(['customsDescription', 'customsOriginCountryCode']),
    showInGoogleProductsFeed: boolean().nullable(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gender: string().when('showInGoogleProductsFeed', (v: any) => {
      if (v?.[0]) {
        return string().required()
      }
      return string().nullable().notRequired()
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ageGroup: string().when('showInGoogleProductsFeed', (v: any) => {
      if (v?.[0]) {
        return string().required()
      }
      return string().nullable().notRequired()
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    googleProductCategory: string().when('showInGoogleProductsFeed', (v: any) => {
      if (v?.[0]) {
        return string().required()
      }
      return string().nullable().notRequired()
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    salePrice: number().when('mobileCheckoutEnabled', (v: any) => {
      if (v?.[0]) {
        return number().required()
      }
      return number().nullable().notRequired()
    }),
    dropshipFees: object()
      .shape({
        act: number().required(),
        nswm: number().required(),
        nswr: number().required(),
        ntm: number().required(),
        ntr: number().required(),
        qldm: number().required(),
        qldr: number().required(),
        remote: number().required(),
        sam: number().required(),
        sar: number().required(),
        tasm: number().required(),
        tasr: number().required(),
        vicm: number().required(),
        vicr: number().required(),
        wam: number().required(),
        war: number().required()
      })
      .nullable()
      .default(undefined)
      .notRequired(),
    rrp: number().nullable().notRequired(),
    customsDescription: string()
      .nullable()
      .requiredIfDependenciesPresent(['packageWeightIntl', 'customsOriginCountryCode']),
    customsOriginCountryCode: string()
      .nullable()
      .requiredIfDependenciesPresent(['packageWeightIntl', 'customsDescription'])
  })

  return ProductSchema
}

export default createProductSchema

import { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import _ from 'lodash'

import { Button } from '~/components/forms'
import { clearProducts } from '~/reducers/products-reducer'
import { fetchProducts } from '~/async-actions/products-async-actions'
import { AppState, AppDispatch } from '~/config/store'
import Product from '~/types/product'
import ProductImportMatcher from '~/types/product-import-matcher'

const Wrapper = styled.div`
  .search-results {
    table tbody {
      cursor: pointer;
    }
  }
`

interface AddUnrecognisedScancodeToProductProps {
  onScancodeAdded?: (produtImportMatcher: ProductImportMatcher) => Promise<void>
  onCancel?: () => void
  existingProduct?: Product
}

const AddUnrecognisedScancodeToProduct = ({
  onScancodeAdded,
  onCancel,
  existingProduct
}: AddUnrecognisedScancodeToProductProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const unmatchedCount = useSelector((state: AppState) => state.unmatchedSalesRecordsPage.list.length)
  const searchResults = useSelector((state: AppState) => state.productsPage.list)
  const searchResultsLoading = useSelector((state: AppState) => state.productsPage.loading.fetchProducts)
  const unmatchedRecord = useSelector((state: AppState) => state.unmatchedSalesRecordsPage.selectedRecord)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [searchText, setSearchText] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(existingProduct || null)

  const addScancodeToProduct = useCallback(async () => {
    if (selectedProduct == null || unmatchedRecord == null) return

    setIsSubmitting(true)

    let response

    try {
      response = await axios.post<ProductImportMatcher>('/api/product_import_matchers', {
        productImportMatcher: {
          productId: selectedProduct.id,
          scancode: unmatchedRecord.importedScancode,
          marketplace: unmatchedRecord.importedSource
        }
      })
    } finally {
      setIsSubmitting(false)
    }

    setSearchText('')
    setSelectedProduct(null)

    const productImportMatcher = response.data
    await onScancodeAdded?.(productImportMatcher)
  }, [onScancodeAdded, unmatchedRecord, selectedProduct])

  const searchForMatchingProducts = useDebouncedCallback(
    useCallback(
      async searchText => {
        await dispatch(fetchProducts({ queryParams: { search: searchText } }))
      },
      [dispatch]
    ),
    500
  )

  const onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = event.target.value
    setSearchText(newSearchText)
    searchForMatchingProducts(newSearchText)
  }

  const selectSearchResult = (product: Product) => {
    setSelectedProduct(product)
  }

  // Might as well cleanup the product page list when unmounting
  useEffect(() => {
    return () => {
      console.log('Unmounting link product widget')
      dispatch(clearProducts())
    }
  }, [dispatch])

  return (
    <Wrapper>
      <div>
        <h2 className="heading">
          Link to existing product <small className="text-secondary">({unmatchedCount - 1} left after this)</small>
        </h2>
        <h5>
          {unmatchedRecord?.importedScancode}{' '}
          <small className="text-secondary">{unmatchedRecord?.importedSource}</small>
        </h5>
      </div>
      <br />

      <div className="fw-bold">Find product</div>

      <input
        className="form-control mb-4"
        type="text"
        id="linkProductBySkuInput"
        value={searchText}
        placeholder="Search products by name or sku"
        onChange={onSearchTextChange}
      />

      {searchResultsLoading && (
        <div>
          <Spinner size="sm" /> Searching...
        </div>
      )}

      {!searchResultsLoading && searchResults.length > 0 && searchText.length > 0 && (
        <div className="search-results">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-nowrap">Custom SKU</th>
                <th>Product Name</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map(product => (
                <tr key={product.id} className="info" onClick={() => selectSearchResult(product)}>
                  <td>{product.customSku}</td>
                  <td style={{ width: '100%' }}>{product.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {(searchResultsLoading || selectedProduct === null) && (
        <div className="mb-3">
          <Button variant="light" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}

      {!searchResultsLoading && selectedProduct !== null && (
        <div>
          <fieldset>
            <legend>Product details</legend>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td style={{ width: '100%' }}>{selectedProduct.name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Description</strong>
                  </td>
                  <td>{selectedProduct.description}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Condition</strong>
                  </td>
                  <td>{selectedProduct.condition}</td>
                </tr>
                <tr>
                  <td>
                    <strong>SKU</strong>
                  </td>
                  <td>{selectedProduct.sku}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Custom SKU</strong>
                  </td>
                  <td>{selectedProduct.customSku}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Shelf location</strong>
                  </td>
                  <td>{selectedProduct.currentShelfLocation}</td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Product scan codes</legend>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Marketplace</th>
                  <th>Scancode</th>
                </tr>
              </thead>
              <tbody>
                {selectedProduct.productImportMatchers?.map(importMatcher => (
                  <tr key={importMatcher.id}>
                    <td>{importMatcher.marketplace}</td>
                    <td>{importMatcher.scancode}</td>
                  </tr>
                ))}
                <tr>
                  <td className="fst-italic">{unmatchedRecord?.importedSource}</td>
                  <td className="text-success fst-italic" style={{ width: '100%' }}>
                    {unmatchedRecord?.importedScancode}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <div className="row mb-3">
            <div className="col-sm-10 d-flex justify-content-end gap-3">
              <Button onClick={addScancodeToProduct} isSubmitting={isSubmitting}>
                Save and Next
              </Button>
              <Button variant="light" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
          <br />
          <br />
        </div>
      )}
    </Wrapper>
  )
}

export default AddUnrecognisedScancodeToProduct

import moment from 'moment'

import Product, { ProductConditionEnum, ProductStatusEnum } from '~/types/product'
import ProductInventory, { ProductInventoryStatusEnum } from '~/types/product-inventory'
import ProductImportMatcher, { ProductImportMatcherMarketplaceTypeEnum } from '~/types/product-import-matcher'
import AccountSettings from '~/types/account-settings'
import { UnmatchedLineItem } from '~/types/ebay-sales-record'

export interface NewProductPojoProps {
  settings?: AccountSettings
  unmatchedRecord?: UnmatchedLineItem
}

export const newInventoryPojo = ({ unmatchedRecord }: { unmatchedRecord?: UnmatchedLineItem } | undefined = {}) => {
  return {
    purchaseDate: moment().format('YYYY-MM-DD') || null,
    quantityIn: null,
    costPricePerUnit: null,
    isPaid: true,
    status: ProductInventoryStatusEnum.RECEIVED,
    onlineQuantity: 0,
    shelfLocation: unmatchedRecord?.salesRecord?.customLabel
  } as ProductInventory
}

export const newProductPojo = ({ unmatchedRecord, settings }: NewProductPojoProps = {}) => {
  return {
    name: unmatchedRecord?.importedScancode,
    customSku:
      unmatchedRecord?.importedSource == ProductImportMatcherMarketplaceTypeEnum.WORDPRESS
        ? unmatchedRecord?.importedScancode
        : '',
    condition: ProductConditionEnum.NEW_WITH_TAGS,
    categories: [],
    status: ProductStatusEnum.ACTIVE,
    productImportMatchers: settings?.productDefaults?.scancodesEnabled
      ? [
          {
            marketplace:
              (unmatchedRecord && unmatchedRecord.importedSource) ||
              settings?.productDefaults.defaultScancodeMarketplace,
            scancode: unmatchedRecord?.importedScancode
          } as ProductImportMatcher
        ]
      : [],
    inventories: [newInventoryPojo({ unmatchedRecord })],
    quantityAvailable: 0,
    featured: false,
    mobile: false,
    mobileCheckoutEnabled: false,
    mobileShowDescription: false,
    mobileShowSpecifications: false,
    showInGoogleProductsFeed: false,
    regularSingleItemWeightBasedShipping: false,
    expressSingleItemWeightBasedShipping: false,
    usesHtmlDescription: false,
    customsDescription: null,
    customsCountryOfOrigin: null
  } as Product
}

import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import Grid from '~/components/grid'
import { AxiosError } from 'axios'
import { default as qs } from 'qs'
import { Spinner } from 'react-bootstrap'

import Icon from '~/components/icon'
import OrderActions from '../../components/order-actions'
import Filters from './filters'
import { fetchOrders } from '~/async-actions/orders-async-actions'
import { gridColumns } from './config'
import { AppState, AppDispatch } from '~/config/store'
import Order from '~/types/order'
import { ListMeta } from '~/reducers/orders-reducer'

const OrderHistoryPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const {
    list,
    listMeta,
    loading: { fetchOrders: loading, bulkUpdateOrders: bulkUpdateLoading }
  } = useSelector((state: AppState) => state.orders)
  // Callback to refresh orders using fetchOrders with order_ids
  const refreshOrdersCallback = async (orderIds: number[]) => {
    return dispatch(fetchOrders({ order_ids: orderIds })).unwrap()
  }

  const handleSetSelectedIds = (data: { selectedIds: number[] }) => {
    setSelectedIds(data.selectedIds)
  }

  const handleOrderClicked = (record: Order) => {
    const path = `/orders/${record.id}/edit`
    navigate(path)
  }

  return (
    <div>
      <div className="d-flex align-items-start justify-content-between">
        <div>
          <h1>Order History</h1>
        </div>
        <>{loading && <Spinner className="ms-4" />}</>
        <a className="btn btn-primary btn-sm ms-auto" href="/order-processing">
          <Icon icon="plus" /> Import Orders
        </a>
      </div>

      <Grid<Order, ListMeta, AxiosError>
        recordType="orderHistory"
        config={{
          columns: gridColumns,
          actions: {
            edit: false, // don't show edit because there is no order-history/xxx/edit route, and there's no other way to configure this currently
            delete: true,
            custom: ({ record }) => {
              return (
                <Link to={`/orders/${record.id}/edit`} target="_blank">
                  <Icon icon="external-link-alt" />
                </Link>
              )
            }
          },
          checkboxesColumn: true,
          onItemSelected: handleSetSelectedIds,
          onRowClick: handleOrderClicked
        }}
        fetchFn={fetchOrders}
        filtersComponent={({ onChange, query }) => (
          <Filters query={query} onChange={params => onChange(params as qs.ParsedQs)} />
        )}
        list={list}
        listMeta={listMeta}
      />

      <OrderActions
        selectedIds={selectedIds}
        ordersList={list}
        refreshCallback={refreshOrdersCallback}
        isExternalBulkUpdateLoading={bulkUpdateLoading}
      />
    </div>
  )
}

export default OrderHistoryPage

import Icon from '~/components/icon'
import { SortDirection } from './SortUtils'
import { tableClasses } from './table-classes'
import { cn } from '~/lib/utils'

// Type for sortable header props
interface SortableHeaderProps {
  label: string
  field: string
  currentField: string
  direction: SortDirection
  onClick: (field: string) => void
  className?: string
  alignment?: 'left' | 'right' | 'center'
}

// Sortable header component
export const SortableHeader: React.FC<SortableHeaderProps> = ({
  label,
  field,
  currentField,
  direction,
  onClick,
  className = tableClasses.headerCell,
  alignment = 'left'
}) => {
  const isCurrentField = currentField === field

  return (
    <th scope="col" className={className}>
      <div
        className={cn(tableClasses.sortable, alignment === 'right' ? 'justify-end' : 'justify-start')}
        onClick={() => onClick(field)}>
        {label}
        <span className="ml-1">
          {isCurrentField && direction === 'asc' && <Icon icon="chevron-up" />}
          {isCurrentField && direction === 'desc' && <Icon icon="chevron-down" />}
          {(!isCurrentField || !direction) && <Icon icon="sort" style={{ opacity: 0.3 }} />}
        </span>
      </div>
    </th>
  )
}

// Cell alignment options
type Alignment = 'left' | 'right' | 'center'

// Table cell props
interface TableCellProps {
  children: React.ReactNode
  alignment?: Alignment
}

// Reusable table cell component
export const TableCell: React.FC<TableCellProps> = ({ children, alignment = 'left' }) => {
  let className = tableClasses.cell
  if (alignment === 'right') className = tableClasses.cellRight
  if (alignment === 'center') className = tableClasses.cellCenter

  return <td className={className}>{children}</td>
}

// Error message component
interface ErrorMessageProps {
  message: string
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => (
  <div className="p-4 bg-red-50 text-red-700 border-l-4 border-red-500 rounded">
    <p>{message}</p>
  </div>
)

// No results component
interface NoResultsProps {
  message?: string
}

export const NoResults: React.FC<NoResultsProps> = ({ message = 'No data available for the selected filters.' }) => (
  <div className="p-8 text-center bg-gray-50 rounded-lg border border-gray-200">
    <p className="text-gray-500">{message}</p>
  </div>
)

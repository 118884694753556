import { ProductStatus } from './types'
import Button from '~/components/forms/button'
import Icon from '~/components/icon'
import SupplierSearchInput from './SupplierSearchInput'

interface StatusFilterFormProps {
  statusFilter: ProductStatus | ''
  onStatusChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onRefresh: () => void
  searchTerm: string
  onSearchChange: (value: string) => void
}

const StatusFilterForm: React.FC<StatusFilterFormProps> = ({
  statusFilter,
  onStatusChange,
  onRefresh,
  searchTerm,
  onSearchChange
}) => {
  const statusOptions = [
    { value: '', label: 'All Products' },
    { value: 'active', label: 'Active Products' },
    { value: 'inactive', label: 'Inactive Products' }
  ]

  return (
    <div className="mb-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Total Stock On Hand Value By Supplier</h1>

      <div className="flex flex-wrap items-center gap-4 mb-4">
        {/* Supplier search filter */}
        <div className="w-full md:w-auto flex-grow md:max-w-xs">
          <SupplierSearchInput
            searchTerm={searchTerm}
            onSearchChange={onSearchChange}
            placeholder="Filter suppliers..."
          />
        </div>

        {/* Status filter */}
        <div className="flex items-center gap-2">
          <label htmlFor="status-select" className="text-sm font-medium text-gray-700 whitespace-nowrap">
            Status:
          </label>
          <select
            id="status-select"
            value={statusFilter}
            onChange={onStatusChange}
            className="py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm">
            {statusOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Refresh button */}
        <Button variant="success" onClick={onRefresh}>
          <Icon icon="arrows-rotate" className="mr-2" /> Refresh
        </Button>
      </div>
    </div>
  )
}

export default StatusFilterForm

import styled from 'styled-components'
import _ from 'lodash'
import { FieldArray, useFormikContext } from 'formik'

import { newInventoryPojo } from '~/lib/utils/helpers'
import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import ProductInventory from '~/types/product-inventory'
import Product from '~/types/product'
import RenderStockHistoryRow from './render-stock-history-row'

const Table = styled.table`
  margin-bottom: 30px;
  border-spacing: 10px 5px;
  border-collapse: separate;
`

const BaseRow = styled.tr``
const BaseCol = styled.th<{ width?: number | string; textAlign?: string }>`
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : '')}
`
const HRow = styled(BaseRow)``
const Row = styled(BaseRow)`
  margin-bottom: 10px;
`

const HCol = styled(BaseCol)`
  font-weight: 600;
`

const Col = styled(BaseCol)``

export type ProductFormValues = Pick<Product, 'inventories' | 'mobile'>

export interface RenderStockHistoryProps {
  readOnly?: boolean
  showTotals?: boolean
}
const RenderStockHistory = ({ readOnly = false, showTotals = true }: RenderStockHistoryProps) => {
  const { values: formValues, errors } = useFormikContext<ProductFormValues>()

  let totalQuantity = 0
  let totalSold = 0

  const defaultRecord = newInventoryPojo()

  return (
    <Table>
      <thead>
        <HRow>
          <HCol>Date</HCol>
          <HCol>Supplier Name</HCol>
          <HCol>Invoice / PO #</HCol>
          <HCol width="60">Qty In</HCol>
          {formValues.mobile && (
            <HCol>
              Qty In
              <br />
              (Online)
            </HCol>
          )}
          {formValues.mobile && (
            <HCol>
              Qty In
              <br />
              (Other)
            </HCol>
          )}
          <HCol>
            Cost <small>(Ex GST)</small>
          </HCol>
          <HCol width="100">Invoice</HCol>
          <HCol width="100">Goods</HCol>
          <HCol width="100">
            Shelf
            <br />
            Location
          </HCol>
          {showTotals === null ||
            (showTotals && (
              <>
                <HCol width="40" textAlign="center">
                  Sold
                  <br />
                  (FIFO)
                </HCol>
                <HCol width="40" textAlign="center">
                  Available
                  <br />
                  (FIFO)
                </HCol>
                {/* NOTE: simon doesn't want to see these columns anymore, so just hiding them

                <HCol width="40" textAlign="center">
                  Sold
                  <br />
                  (period)
                </HCol>
                */}
              </>
            ))}
          {/* NOTE: simon doesn't want to see these columns anymore, so just hiding them

          {showTotals === null ||
            (showTotals && formValues.mobile && (
              <HCol width="40" textAlign="center">
                Mob
              </HCol>
            ))}
          {showTotals === null ||
            (showTotals && formValues.mobile && (
              <HCol width="40" textAlign="center">
                Oth
              </HCol>
            ))}
            */}
          <HCol width="40" />
        </HRow>
      </thead>

      <tbody>
        <FieldArray name="inventories">
          {arrayHelpers => (
            <>
              {(formValues.inventories || []).map((inventory: ProductInventory, index: number) => {
                totalQuantity += Number(inventory.quantityIn) || 0
                totalSold += Number(inventory.quantitySoldFifo) || 0
                const existingRecord = Boolean(inventory.id)

                const key = formValues?.inventories?.[index].id || `idx${index}`

                return (
                  <RenderStockHistoryRow
                    key={key}
                    index={index}
                    formValues={formValues}
                    showTotals={showTotals}
                    readOnly={readOnly}
                    existingRecord={existingRecord}
                    onRemove={() => arrayHelpers.remove(index)}
                  />
                )
              })}

              {showTotals && (
                <Row>
                  <Col>
                    <Button
                      variant="light"
                      onClick={() => arrayHelpers.push(_.cloneDeep(defaultRecord) as ProductInventory)}>
                      Add&nbsp;&nbsp;
                      <Icon icon="plus" />
                    </Button>
                    {typeof errors.inventories === 'string' && (
                      <span className="form-text text-danger">{errors.inventories}</span>
                    )}
                  </Col>
                  <Col>
                    <strong>Total Quantity: {totalQuantity}</strong>
                  </Col>
                  <Col>
                    <strong>Total Sold: {totalSold}</strong>
                  </Col>
                </Row>
              )}
            </>
          )}
        </FieldArray>
      </tbody>
    </Table>
  )
}

export default RenderStockHistory

import { useImperativeHandle, useState, useEffect, forwardRef, useCallback } from 'react'
import { Field, useFormikContext } from 'formik'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import standingDataService from '~/services/standing-data-service'
import { ReactSelectInputCreatable, BsSelectInput } from '~/components/form-inputs/formik/inputs-decorated'
import RenderStockHistory from '../render-stock-history'
import { ProductFormValues } from '../use-product-form'
import { AppState } from '~/config/store'

interface DropdownOption {
  label: string
  value: string
}

const statusOptions: DropdownOption[] = [
  { value: '', label: 'Please select' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' }
]

export interface InventoryManagementBasicRef {
  addCategories: (categories: DropdownOption[]) => void
}

const InventoryManagementBasic = forwardRef((_props, ref) => {
  const { values: formValues } = useFormikContext<ProductFormValues>()
  const [categoryOptions, setCategoryOptions] = useState<DropdownOption[]>([])
  const [categoryOptionsLoaded, setCategoryOptionsLoaded] = useState<boolean>(false)
  const summary = useSelector((state: AppState) => state.productsPage.currentProductInventorySummary)

  useImperativeHandle(ref, () => ({
    addCategories: (categories: DropdownOption[]) => {
      setCategoryOptions(prevCategories => _.uniqBy([...prevCategories, ...categories], 'value'))
      standingDataService.addProductCategoriesOptions(categories)
    }
  }))

  const fetchCategoryOptions = useCallback(async () => {
    if (categoryOptionsLoaded) return
    const result = await standingDataService.fetchProductCategoriesOptions()
    setCategoryOptions(result)
    setCategoryOptionsLoaded(true)
  }, [categoryOptionsLoaded])

  useEffect(() => {
    fetchCategoryOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <fieldset>
      <legend>Inventory Management</legend>

      {!formValues.id && <RenderStockHistory showTotals={false} />}

      <div className="row">
        <div className="col-sm-6">
          <Field isMulti name="categories" component={ReactSelectInputCreatable} options={categoryOptions} />
          <Field required name="status" label="Status" component={BsSelectInput} options={statusOptions} />
        </div>

        <div className="col-sm-6">
          {formValues.id != null && summary != null && summary.quantityAvailable != null && (
            <div className="row">
              <div className="col-sm-8 text-end">
                {formValues.mobile && <div>Currently advertised in mobile/shopfront stores</div>}
                {formValues.mobile && <div>Available for POS / eBay</div>}
                <div>Total quantity available for sale</div>
                {formValues.mobile && <hr />}
                <div>Awaiting processing</div>
                <div>Total quantity in stock</div>
              </div>
              <div className="col-sm-4">
                {formValues.mobile && <div>{summary.onlineQuantityAvailable}</div>}
                {formValues.mobile && <div>{summary.otherQuantityAvailable}</div>}
                <div>{summary.quantityAvailable}</div>
                {formValues.mobile && <hr />}
                <div>{summary.quantitySoldUnprocessed}</div>
                <div>{summary.quantityAvailable + summary.quantitySoldUnprocessed}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </fieldset>
  )
})

InventoryManagementBasic.displayName = 'InventoryManagementBasic'

export default InventoryManagementBasic

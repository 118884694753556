import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { fetchOrders, fetchOrder, bulkUpdateOrders } from '~/async-actions/orders-async-actions'

import Order from '~/types/order'

export interface ListMeta {
  currentPage: number
  totalPages: number
  totalCount: number
  pageSize: number
}

export interface OrdersState {
  list: Order[]
  listMeta?: ListMeta
  selectedOrder: Order | null
  loading: {
    fetchOrders: boolean
    fetchOrder: boolean
    bulkUpdateOrders: boolean
  }
  error: {
    fetchOrders: string | null
    fetchOrder: string | null
    bulkUpdateOrders: string | null
  }
}

const initialState: OrdersState = {
  list: [],
  listMeta: undefined,
  selectedOrder: null,
  loading: {
    fetchOrders: false,
    fetchOrder: false,
    bulkUpdateOrders: false
  },
  error: {
    fetchOrders: null,
    fetchOrder: null,
    bulkUpdateOrders: null
  }
}

const sortOrders = (orders: Order[]): Order[] => {
  return _.orderBy(orders, 'id', 'desc')
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    changeSelectedOrder: (state, action: PayloadAction<Order | null>) => {
      state.selectedOrder = action.payload
    },
    updateOrder: (state, action: PayloadAction<Order>) => {
      const newList = state.list.filter(order => order.id !== action.payload.id)
      newList.push(action.payload)
      state.list = sortOrders(newList)
    }
  },
  extraReducers: builder => {
    builder
      // fetchOrders async action
      .addCase(fetchOrders.pending, state => {
        state.loading.fetchOrders = true
        state.error.fetchOrders = null
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.list = sortOrders(action.payload.items)
        state.listMeta = action.payload.meta
        state.loading.fetchOrders = false
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading.fetchOrders = false
        state.error.fetchOrders = action.payload?.message || 'Error fetching orders'
      })

      // fetchOrder async action
      .addCase(fetchOrder.pending, state => {
        state.loading.fetchOrder = true
        state.error.fetchOrder = null
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.selectedOrder = action.payload
        state.loading.fetchOrder = false
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.loading.fetchOrder = false
        state.error.fetchOrder = action.payload?.message || 'Error fetching orders'
      })

      // bulkUpdateOrders async action
      .addCase(bulkUpdateOrders.pending, state => {
        state.loading.bulkUpdateOrders = true
        state.error.bulkUpdateOrders = null
      })
      .addCase(bulkUpdateOrders.fulfilled, state => {
        state.loading.bulkUpdateOrders = false
      })
      .addCase(bulkUpdateOrders.rejected, (state, action) => {
        state.loading.bulkUpdateOrders = false
        state.error.bulkUpdateOrders = action.payload?.message || 'Error updating orders'
      })
  }
})

export const { changeSelectedOrder, updateOrder } = ordersSlice.actions

export default ordersSlice.reducer

import { useCallback, useState, useRef, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import styled from 'styled-components'

import { fetchProducts } from '~/async-actions/products-async-actions'
import Product from '~/types/product'
import { AppDispatch } from '~/config/store'
import { PosFormValues } from './form'

const SearchRow = styled.div`
  margin-top: 10px;

  select {
    min-width: 500px;
  }
`

const ProductSearchRow = () => {
  const [searchText, setSearchText] = useState('')
  const [selectedProduct, setSelectedProduct] = useState('')
  const [showSelectBox, setShowSelectBox] = useState(false)
  const [products, setProducts] = useState<Product[]>([])

  const productSelectInstance = useRef<HTMLSelectElement>(null)

  const dispatch = useDispatch<AppDispatch>()

  const {
    values: { lineItems },
    setFieldValue
  } = useFormikContext<PosFormValues>()
  const currentProductIds = _.map(lineItems, line => line.productId)

  const onAdd = useCallback(
    (product: Product) => {
      const line = {
        product,
        productId: product.id,
        quantity: 1,
        unitPrice: product.salePrice,
        quantityAvailable: product.mobile ? product.otherQuantityAvailable : product.quantityAvailable
      }

      setFieldValue('lineItems', [...lineItems, line])
    },
    [lineItems, setFieldValue]
  )

  const handleProductSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const productId = parseInt(e.target.value, 10)

      setSelectedProduct(productId.toString())
      const product = _.find(products, item => item.id === productId)
      if (product) {
        onAdd(product)
      }
      setSearchText('')
      setSelectedProduct('')
      setShowSelectBox(false)
    },
    [onAdd, products]
  )

  const handleSearch = useDebouncedCallback(
    useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value
        // TODO: it would be good if this didn't clobber the redux state!
        const response = await dispatch(fetchProducts({ queryParams: { search: searchText } }))
        const { items: searchResults } = response.payload

        const newProducts = _.filter(searchResults, p => !currentProductIds.includes(p.id))

        if (newProducts.length === 1 && newProducts[0].barcode === searchText) {
          handleProductSelectChange(_.set({}, 'target.value', newProducts[0].id) as ChangeEvent<HTMLSelectElement>)
        }

        const showSelect = newProducts.length > 0

        setProducts(newProducts)
        setShowSelectBox(showSelect)

        if (productSelectInstance.current) {
          productSelectInstance.current.size = newProducts.length > 9 ? 9 : newProducts.length + 1
        }
      },
      [dispatch, currentProductIds, handleProductSelectChange]
    ),
    500
  )

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
      handleSearch(e)
    },
    [handleSearch]
  )

  return (
    <SearchRow className="row">
      <div className="col-sm-12">
        <input
          className="form-control"
          type="text"
          value={searchText}
          placeholder="Type to add a product by sku, name, or barcode"
          onChange={handleInputChange}
        />
        <select
          ref={productSelectInstance}
          className="form-select"
          value={selectedProduct}
          style={{ display: showSelectBox ? 'block' : 'none' }}
          onChange={handleProductSelectChange}>
          <option value="">Please select</option>
          {products.map(p => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </select>
      </div>
    </SearchRow>
  )
}

export default ProductSearchRow

import StockOnHandValueBySupplierReport from './stock-on-hand-value-by-supplier'
import ProductsForInvoiceOrSupplierReport from './products-for-invoice-or-supplier'

type Section = {
  path: string
  label: string
  icon: string
  component: React.ComponentType
  userKind?: string[]
}

const SectionsIndex: Section[] = [
  {
    path: '/stock-on-hand-value-by-supplier',
    label: 'Stock On Hand Value By Supplier',
    icon: 'dollar-sign',
    component: StockOnHandValueBySupplierReport,
    userKind: ['owner']
  },
  {
    path: '/products-for-invoice-or-supplier',
    label: 'Products for Invoice or Supplier',
    icon: 'shopping-cart',
    component: ProductsForInvoiceOrSupplierReport,
    userKind: ['owner']
  }
]

export default SectionsIndex

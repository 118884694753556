import { useMemo, useEffect } from 'react'

import { cn } from '~/lib/utils'
import { SupplierStockItem } from './types'
import { tableClasses } from './table-classes'
import { SortState, sortItems } from './SortUtils'
import { SortableHeader, TableCell, NoResults } from './TableComponents'
import { formatCurrency } from '~/lib/format-utils'

interface SupplierTableProps {
  suppliers: SupplierStockItem[]
  grandTotal: string
  statusFilter: string
  searchTerm: string
  sortState: SortState
  onSortChange: (field: string) => void
  onFilteredTotalChange?: (total: string) => void
}

const SupplierTable: React.FC<SupplierTableProps> = ({
  suppliers,
  grandTotal,
  statusFilter,
  searchTerm,
  sortState,
  onSortChange,
  onFilteredTotalChange
}) => {
  // Filter suppliers based on search term (case-insensitive, partial match)
  const filteredSuppliers = useMemo(() => {
    if (!searchTerm) return suppliers

    const lowerSearch = searchTerm.toLowerCase()
    return suppliers.filter(supplier => supplier.supplier?.toLowerCase().includes(lowerSearch))
  }, [suppliers, searchTerm])

  // Apply sorting after filtering
  const sortedSuppliers = useMemo(() => {
    return sortItems(filteredSuppliers, sortState)
  }, [filteredSuppliers, sortState])

  // Calculate filtered total
  const filteredTotal = useMemo(() => {
    if (filteredSuppliers.length === suppliers.length) return grandTotal

    return filteredSuppliers
      .reduce((sum, supplier) => {
        return sum + parseFloat(supplier.totalStockValue)
      }, 0)
      .toFixed(2)
  }, [filteredSuppliers, suppliers.length, grandTotal])

  // Notify parent component about filtered total changes
  useEffect(() => {
    if (onFilteredTotalChange) {
      onFilteredTotalChange(filteredTotal)
    }
  }, [filteredTotal, onFilteredTotalChange])

  if (suppliers.length === 0) {
    return <NoResults />
  }

  if (sortedSuppliers.length === 0) {
    return <NoResults message="No suppliers match your search criteria." />
  }

  return (
    <div className={cn(tableClasses.container, 'max-w-md')}>
      <table className={tableClasses.table}>
        <thead className={tableClasses.thead}>
          <tr>
            <SortableHeader
              label="Supplier"
              field="supplier"
              currentField={sortState.field}
              direction={sortState.direction}
              onClick={onSortChange}
            />
            <SortableHeader
              label="Total Stock Value"
              field="totalStockValue"
              currentField={sortState.field}
              direction={sortState.direction}
              onClick={onSortChange}
              className={tableClasses.headerCellRight}
              alignment="right"
            />
          </tr>
        </thead>
        <tbody className={tableClasses.tbody}>
          {sortedSuppliers.map((supplier, index) => {
            const supplierName =
              supplier.supplier === null ? 'null' : supplier.supplier === '' ? 'blank' : supplier.supplier
            return (
              <tr key={index} className={tableClasses.row}>
                <TableCell>
                  <a
                    href={`/reports/products-for-invoice-or-supplier?supplier_name=${encodeURIComponent(
                      supplierName
                    )}&status=${statusFilter}&group_by=supplier_name`}
                    className={tableClasses.link}>
                    {supplierName}
                  </a>
                </TableCell>
                <TableCell alignment="right">{formatCurrency(supplier.totalStockValue)}</TableCell>
              </tr>
            )
          })}
        </tbody>
        <tfoot className={tableClasses.tfoot}>
          <tr className={tableClasses.footerRow}>
            <th className={tableClasses.footerCell}>
              {searchTerm && filteredSuppliers.length !== suppliers.length ? (
                <span>
                  Filtered Total ({filteredSuppliers.length} of {suppliers.length} suppliers)
                </span>
              ) : (
                <span>Grand Total</span>
              )}
            </th>
            <th className={tableClasses.footerCellRight}>{formatCurrency(filteredTotal)}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default SupplierTable

import { Link } from 'react-router-dom'

import { tableClasses } from './table-classes'
import { DenormalizedInventoryItem, GroupBy } from './types'
import { TableCell } from './TableComponents'
import ProductStatusBadge from './ProductStatusBadge'
import { formatCurrency, formatDate } from '~/lib/format-utils'

interface InventoryTableRowProps {
  item: DenormalizedInventoryItem
  groupBy: GroupBy
  index: number
}

const InventoryTableRow: React.FC<InventoryTableRowProps> = ({ item, groupBy, index }) => {
  const isSoldOut = item.currentQuantity === 0
  return (
    <tr key={index} className={tableClasses.row}>
      {groupBy !== 'product' && (
        <>
          <TableCell>
            <Link to={`/products/${item.productId}/edit`} className={tableClasses.link}>
              {item.productName}
            </Link>
          </TableCell>
          <TableCell>
            <ProductStatusBadge status={item.productStatus} />
          </TableCell>
        </>
      )}
      {groupBy !== 'invoice_no' && (
        <TableCell>
          <Link
            to={`/reports/products-for-invoice-or-supplier?invoice_no=${encodeURIComponent(
              item.invoiceNo
            )}&group_by=invoice_no`}
            className={tableClasses.link}>
            {item.invoiceNo}
          </Link>
        </TableCell>
      )}
      {groupBy !== 'supplier_name' && (
        <TableCell>
          <Link
            to={`/reports/products-for-invoice-or-supplier?supplier_name=${encodeURIComponent(
              item.supplierName
            )}&group_by=supplier_name`}
            className={tableClasses.link}>
            {item.supplierName}
          </Link>
        </TableCell>
      )}
      <TableCell>{formatDate(item.purchaseDate)}</TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <>{item.shelfLocation}</>
          {isSoldOut && <span className="font-bold text-red-500">OUT</span>}
        </div>
      </TableCell>
      <TableCell align="right">{item.originalQuantity}</TableCell>
      <TableCell align="right">{item.quantitySoldFifo}</TableCell>
      <TableCell align="right">{item.currentQuantity}</TableCell>
      <TableCell align="right">{formatCurrency(item.costPricePerUnit)}</TableCell>
      <TableCell align="right">{formatCurrency(item.totalCost)}</TableCell>
      <TableCell align="right">{formatCurrency(item.currentValue)}</TableCell>
    </tr>
  )
}

export default InventoryTableRow

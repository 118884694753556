import { ReactNode, KeyboardEvent, createRef, useEffect, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { default as qs } from 'qs'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { Spinner } from 'react-bootstrap'

import useAuth from '~/contexts/useAuth'
import useSettings from '~/contexts/useSettings'
import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import ExportButton from '~/components/export-button'
import { PRODUCTS_GRID_COLUMNS, ProductsGridColumns } from './columns'
import { fetchProducts } from '~/async-actions/products-async-actions'
import ProductsTable from './products-table'
import ProductsGridCustomizerModal from './products-grid-customizer-modal'
import { EbaySearch as EbaySearch_ } from './ebay-search'
import SearchBox_ from './search-box'
import { AppState, AppDispatch } from '~/config/store'
import { ProductStatusFilterStatus } from './product-status-filter'
import { ProductInventoryFilterStatus } from './product-inventory-status-filter'

const TopRow = styled.div`
  padding: 0 0 20px 20px;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
`
const CustomiseBtn = styled(Button)`
  height: 30px;
`

const Heading = styled.h1`
  margin: 0;
  margin-right: 20px;
  font-size: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Wrapper = styled.div``
const SearchCell = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`
const BoxEbay = styled.div`
  margin-left: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
`
const EbaySearch = styled(EbaySearch_)`
  margin-left: 1rem;
`
const EbayCheckbox = styled.input`
  margin: 10px !important;
`
const SearchBox = styled(SearchBox_)`
  flex: 1;
`

interface SearchFilters {
  page: number
  search: string
  categories: string[]
  status: ProductStatusFilterStatus
  inventory_status?: ProductInventoryFilterStatus
  shelf_location?: string
}

interface ProductsPageProps {
  children?: ReactNode
}
const ProductsPage = ({ children }: ProductsPageProps) => {
  const {
    list,
    listMeta,
    loading: { fetchProducts: loading }
  } = useSelector((state: AppState) => state.productsPage)
  const visibleProductsGridColumnIds = useSelector(
    (state: AppState) => state.persistableData.visibleProductsGridColumnIds
  )
  const { settings: accountSettings } = useSettings()
  const { userProfile } = useAuth()
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const navigate = useNavigate()
  const ebaySearchLinkRef = createRef<HTMLAnchorElement>()
  const abortController = useRef(new AbortController())

  const [searchText, setSearchText] = useState('')
  const [openEbaySearch, setOpenEbaySearch] = useState(false)
  const [showGridCustomizerModal, setShowGridCustomizerModal] = useState(false)

  const findProducts = useCallback(
    (newFilters: SearchFilters) => {
      navigate({
        pathname: '/products',
        search: qs.stringify(newFilters)
      })
      dispatch(fetchProducts({ queryParams: newFilters, signal: abortController.current.signal }))
    },
    [dispatch, navigate, abortController]
  )

  const findProductsDebounced = useDebouncedCallback(findProducts, 700, { trailing: true })

  const _getStatus = useCallback(() => {
    return (qs.parse(location.search || '', { ignoreQueryPrefix: true }).status ||
      ProductStatusFilterStatus.ALL) as ProductStatusFilterStatus
  }, [location.search])

  const _getInventoryStatus = useCallback(() => {
    const params = qs.parse(location.search || '', { ignoreQueryPrefix: true })
    const status = params.inventory_status
    return status ? (status as ProductInventoryFilterStatus) : undefined
  }, [location.search])

  const _getShelfLocation = useCallback(() => {
    const params = qs.parse(location.search || '', { ignoreQueryPrefix: true })
    const locationParam = params.shelf_location
    return locationParam ? locationParam.toString() : undefined
  }, [location.search])

  const _getActiveCategories = useCallback(() => {
    const str = qs.parse(location.search || '', { ignoreQueryPrefix: true }).categories || ''
    return _.without(str.toString().split(','), '')
  }, [location.search])

  const _getSearchText = useCallback(() => {
    return (qs.parse(location.search || '', { ignoreQueryPrefix: true }).search || '').toString()
  }, [location.search])

  const _getPageNum = useCallback(() => {
    return Number(qs.parse(location.search || '', { ignoreQueryPrefix: true }).page || 1)
  }, [location.search])

  const _getCurrentFilters = useCallback(() => {
    const filters: SearchFilters = {
      page: _getPageNum(),
      search: _getSearchText(),
      categories: _getActiveCategories(),
      status: _getStatus()
    }

    const inventoryStatus = _getInventoryStatus()
    if (inventoryStatus) {
      filters.inventory_status = inventoryStatus
    }

    const shelfLocation = _getShelfLocation()
    if (shelfLocation) {
      filters.shelf_location = shelfLocation
    }

    return filters
  }, [_getPageNum, _getSearchText, _getActiveCategories, _getStatus, _getInventoryStatus, _getShelfLocation])

  useEffect(() => {
    const controller = abortController.current

    dispatch(fetchProducts({ queryParams: _getCurrentFilters() }))

    return () => {
      controller.abort()
    }
  }, [dispatch, _getCurrentFilters])

  const handleSearchTextChange = useCallback(
    (searchText?: string, debounce = true) => {
      const search = _.trimStart(searchText).replace(/\s+/g, ' ')
      setSearchText(search)

      if (_getSearchText() !== search) {
        // Note: we are also reseting the page to 1
        const newFilters = { ..._getCurrentFilters(), search, page: 1 }
        if (debounce === true) {
          findProductsDebounced(newFilters)
        } else {
          abortController.current.abort()
          findProducts(newFilters)
        }
      }
    },
    [findProductsDebounced, abortController, _getCurrentFilters, _getSearchText, findProducts]
  )

  const openEbaySearchIfNeeded = (search: string) => {
    if (!search || openEbaySearch !== true) {
      return
    }
    ebaySearchLinkRef.current?.click()
  }

  const handleSearchKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event?.key === 'Enter') {
      event.currentTarget.select() // Select and highlight searchText
      handleSearchTextChange(event.currentTarget.value, false)
      openEbaySearchIfNeeded(searchText)
    }
  }

  const _handleStatusChange = (status: ProductStatusFilterStatus) => {
    if (_getStatus() !== status) {
      // Note: we are also reseting the page to 1
      const newFilters = { ..._getCurrentFilters(), status, page: 1 }
      navigate({
        pathname: '/products',
        search: qs.stringify(newFilters)
      })
      dispatch(fetchProducts({ queryParams: newFilters }))
    }
  }

  const _handleInventoryStatusChange = (inventoryStatus: ProductInventoryFilterStatus) => {
    if (_getInventoryStatus() !== inventoryStatus) {
      // Note: we are also reseting the page to 1
      const newFilters = { ..._getCurrentFilters(), page: 1 }

      // Only add inventory_status if it's not ALL
      if (inventoryStatus !== ProductInventoryFilterStatus.ALL) {
        newFilters.inventory_status = inventoryStatus
      } else {
        delete newFilters.inventory_status
      }

      navigate({
        pathname: '/products',
        search: qs.stringify(newFilters)
      })
      dispatch(fetchProducts({ queryParams: newFilters }))
    }
  }

  const _handleActiveCategoriesChange = (categories: string[]) => {
    const categoriesList = categories.join(',')
    // Note: we are also reseting the page to 1
    const newFilters = { ..._getCurrentFilters(), categories: categoriesList, page: 1 }
    navigate({
      pathname: '/products',
      search: qs.stringify(newFilters)
    })
    dispatch(fetchProducts({ queryParams: newFilters }))
  }

  const _getAvailableColumns = () => {
    return _.filter(
      PRODUCTS_GRID_COLUMNS,
      column => column.id !== 'mobile' || accountSettings?.account?.mobileEnabled
    ) as ProductsGridColumns[]
  }

  const _getVisibleColumns = () => {
    const columns: ProductsGridColumns[] = []

    const visibleIds = visibleProductsGridColumnIds || []
    const orderedAllIds: string[] = _.map(_getAvailableColumns(), 'id') || []
    const orderedVisibleIds = _.filter(orderedAllIds, id => visibleIds.includes(id))

    _.each(orderedVisibleIds, columnId => {
      const column = _.find(_getAvailableColumns(), { id: columnId }) as ProductsGridColumns
      if (column) columns.push(column)
    })

    return columns
  }

  const _productsListHtml = () => {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6 d-flex gap-3 mb-5">
            <Button onClick={() => navigate('/products/new')}>
              <Icon icon="plus" /> New Product
            </Button>
            {userProfile && userProfile.kind === 'owner' && (
              <Button onClick={() => navigate('/products/bulk-photos')}>
                <Icon icon="plus" /> Add Images
              </Button>
            )}
            <Link className="btn btn-primary" to="/products/import">
              <Icon icon="plus" /> Import
            </Link>
            {userProfile && userProfile.kind === 'owner' && (
              <ExportButton endpoint="/api/products/export" icon="file-excel" />
            )}
          </div>
          <div className="col-sm-6">
            <SearchCell>
              <SearchBox
                value={searchText}
                onChange={e => handleSearchTextChange(e.target.value)}
                onKeyDown={handleSearchKeyPress}
              />
              <BoxEbay>
                <EbaySearch ref={ebaySearchLinkRef} value={searchText} />
                <EbayCheckbox
                  type="checkbox"
                  checked={openEbaySearch}
                  onChange={e => setOpenEbaySearch(e.target.checked)}
                />
              </BoxEbay>
            </SearchCell>
          </div>
        </div>

        <ProductsTable
          list={list}
          listMeta={listMeta}
          columns={_getVisibleColumns()}
          filters={_getCurrentFilters()}
          onActiveCategoriesChange={_handleActiveCategoriesChange}
          onStatusChange={_handleStatusChange}
          onInventoryStatusChange={_handleInventoryStatusChange}
        />
      </div>
    )
  }

  return (
    <Wrapper>
      {!children && (
        <div>
          <TopRow>
            <Heading>
              <>Products</>
              {loading && <Spinner className="ms-4" />}
            </Heading>
            <CustomiseBtn variant="light" size="sm" onClick={() => setShowGridCustomizerModal(true)}>
              Customise Grid
            </CustomiseBtn>
          </TopRow>
        </div>
      )}

      {children || _productsListHtml()}

      {showGridCustomizerModal && (
        <ProductsGridCustomizerModal
          show={showGridCustomizerModal}
          allColumns={_getAvailableColumns()}
          onHide={() => setShowGridCustomizerModal(false)}
        />
      )}
    </Wrapper>
  )
}

export default ProductsPage

import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { useSearchParams } from 'react-router-dom'

import useAuth from '~/contexts/useAuth'
import { UserKindEnum } from '~/types/user'
import {
  ApiErrorResponse,
  InvoiceInventoryReportResponse,
  InventorySearchValues,
  ProductStatus,
  GroupBy
} from './types'
import Spinner from '~/components/spinner'
import InventorySearchForm from './InventorySearchForm'
import ProductResults from './ProductResults'

// Validation schema using Yup
const SearchSchema = object()
  .shape({
    invoiceNo: string(),
    supplierName: string(),
    status: string()
  })
  .test(
    'at-least-one-field',
    'Please enter either an invoice number or supplier name',
    values => !!values.invoiceNo || !!values.supplierName
  )

const InvoiceInventoryReport = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [reportData, setReportData] = useState<InvoiceInventoryReportResponse['reportData']>([])
  const [queryType, setQueryType] = useState<InvoiceInventoryReportResponse['queryType']>(null)
  const [queryValue, setQueryValue] = useState<string | null>(null)
  const [groupBy, setGroupBy] = useState<GroupBy>('product')

  // Use react-router hooks for handling URL parameters
  const [searchParams, setSearchParams] = useSearchParams()

  // Fetch data based on current form values and groupBy
  const fetchData = useCallback(async (values: InventorySearchValues) => {
    if (!values.invoiceNo && !values.supplierName) {
      setError('Please enter either an invoice number or supplier name')
      return Promise.reject(new Error('Missing required fields'))
    }

    setLoading(true)
    setError(null)

    try {
      const params: Record<string, string> = {}
      if (values.invoiceNo) {
        params.invoice_no = values.invoiceNo
      }

      if (values.supplierName) {
        params.supplier_name = values.supplierName
      }

      // Add status filter if selected
      if (values.status) {
        params.status = values.status
      }

      const response = await axios.get<InvoiceInventoryReportResponse | ApiErrorResponse>(
        '/api/inventory/invoice_report',
        { params }
      )

      if (response.data.success) {
        const data = response.data as InvoiceInventoryReportResponse
        setReportData(data.reportData)
        setQueryType(data.queryType)
        setQueryValue(data.queryValue)
        return Promise.resolve()
      } else {
        const errorData = response.data as ApiErrorResponse
        const errorMessage = errorData.error || 'Failed to fetch report data'
        setError(errorMessage)
        return Promise.reject(new Error(errorMessage))
      }
    } catch (err) {
      let errorMessage = 'An unknown error occurred'
      if (axios.isAxiosError(err)) {
        errorMessage = `Error: ${err.message}`
        setError(errorMessage)
      } else {
        setError(errorMessage)
      }
      return Promise.reject(new Error(errorMessage))
    } finally {
      setLoading(false)
    }
  }, [])

  // Handle form submission
  const handleSearch = useCallback(
    async (values: InventorySearchValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
      // Update URL parameters
      const urlParams = new URLSearchParams()
      if (values.invoiceNo) urlParams.set('invoice_no', values.invoiceNo)
      if (values.supplierName) urlParams.set('supplier_name', values.supplierName)
      if (values.status) urlParams.set('status', values.status)
      urlParams.set('group_by', groupBy)
      setSearchParams(urlParams)

      try {
        // Fetch data
        await fetchData(values)
      } finally {
        // Ensure setSubmitting is called even if fetchData throws an error
        setSubmitting(false)
      }
    },
    [groupBy, setSearchParams, fetchData]
  )

  const formik = useFormik({
    initialValues: {
      invoiceNo: '',
      supplierName: '',
      status: ''
    },
    validationSchema: SearchSchema,
    onSubmit: handleSearch
  })

  // Handle groupBy change
  const handleGroupByChange = useCallback(
    (newGroupBy: GroupBy) => {
      setGroupBy(newGroupBy)

      // Update URL with new groupBy
      const urlParams = new URLSearchParams(searchParams)
      urlParams.set('group_by', newGroupBy)
      setSearchParams(urlParams)
    },
    [searchParams, setSearchParams]
  )

  // Load data from URL parameters on component mount
  useEffect(() => {
    const loadDataFromURL = () => {
      const invoiceNo = searchParams.get('invoice_no') || ''
      const supplierName = searchParams.get('supplier_name') || ''
      const status = searchParams.get('status') || ''
      const groupByParam = searchParams.get('group_by') as GroupBy | null

      // Set groupBy if present in URL
      if (groupByParam && ['product', 'invoice_no', 'supplier_name'].includes(groupByParam)) {
        setGroupBy(groupByParam as GroupBy)
      }

      // Update form values with URL parameters
      formik.setValues({
        invoiceNo,
        supplierName,
        status: status as ProductStatus
      })

      // Fetch data if we have search parameters
      if (invoiceNo || supplierName) {
        fetchData({ invoiceNo, supplierName, status: status as ProductStatus }).catch(err => {
          // Error already handled in fetchData
          console.error('Error loading data from URL:', err)
        })
      }
    }

    // Load data on initial render
    loadDataFromURL()

    // Add event listener for popstate (browser back/forward)
    const handlePopState = () => {
      // Reload the page when navigating with browser back/forward
      // This is a simpler solution than trying to sync everything with the URL params
      window.location.reload()
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Add a separate effect to listen for URL changes
  useEffect(() => {
    // This will get triggered when the searchParams change
    const invoiceNo = searchParams.get('invoice_no') || ''
    const supplierName = searchParams.get('supplier_name') || ''
    const status = searchParams.get('status') || ''
    const groupByParam = searchParams.get('group_by') as GroupBy | null

    // This check prevents unnecessary data fetching during normal operation
    const formValues = formik.values
    const isFormChanged =
      invoiceNo !== formValues.invoiceNo || supplierName !== formValues.supplierName || status !== formValues.status

    // Only trigger a new fetch if the URL parameters actually changed
    if (isFormChanged && (invoiceNo || supplierName)) {
      console.log('URL params changed, loading new data')

      // Update form values to match URL
      formik.setValues({
        invoiceNo,
        supplierName,
        status: status as ProductStatus
      })

      // Set groupBy if present in URL
      if (groupByParam && ['product', 'invoice_no', 'supplier_name'].includes(groupByParam)) {
        setGroupBy(groupByParam as GroupBy)
      }

      // Fetch data with the new parameters
      fetchData({ invoiceNo, supplierName, status: status as ProductStatus })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  // Security check
  const { userProfile } = useAuth()
  if (userProfile?.kind !== UserKindEnum.OWNER) return null

  return (
    <div className="mx-auto">
      <InventorySearchForm
        formik={formik}
        loading={loading}
        error={error}
        groupBy={groupBy}
        setGroupBy={handleGroupByChange}
      />

      {loading ? (
        <Spinner label="Loading report data..." />
      ) : (
        <ProductResults reportData={reportData} queryType={queryType} queryValue={queryValue} groupBy={groupBy} />
      )}
    </div>
  )
}

export default InvoiceInventoryReport

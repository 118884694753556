import { DenormalizedInventoryItem, GroupBy } from './types'
import { SortDirection } from './TableComponents'
import { tableClasses } from './table-classes'
import InventoryTableHeader from './InventoryTableHeader'
import InventoryTableRow from './InventoryTableRow'
import { sortItems } from './SortUtils'
import { formatCurrency } from '~/lib/format-utils'

interface InventoryTableProps {
  items: DenormalizedInventoryItem[]
  groupBy: GroupBy
  sortField: keyof DenormalizedInventoryItem | null
  sortDirection: SortDirection
  onSort: (field: keyof DenormalizedInventoryItem | null) => void
  groupTitle?: React.ReactNode
}

const InventoryTable: React.FC<InventoryTableProps> = ({
  items,
  groupBy,
  sortField,
  sortDirection,
  onSort,
  groupTitle
}) => {
  // Calculate totals for the group
  const totalCost = items.reduce((sum, item) => sum + parseFloat(item.totalCost), 0)
  const totalCurrentValue = items.reduce((sum, item) => sum + parseFloat(item.currentValue), 0)

  // Calculate the colspan for the total label
  const getColspan = () => {
    // Count columns based on which are shown
    let colCount = 8 // Base columns (date, qty fields, etc)

    // Add columns based on grouping
    if (groupBy !== 'product') colCount += 2 // Product name & status
    if (groupBy !== 'invoice_no') colCount += 1 // Invoice #
    if (groupBy !== 'supplier_name') colCount += 1 // Supplier name

    return colCount - 2 // Subtract 2 for the total cols which are separate
  }

  return (
    <div className="overflow-x-auto">
      <table className={tableClasses.table}>
        <thead className={tableClasses.tableHead}>
          <InventoryTableHeader
            groupBy={groupBy}
            sortField={sortField}
            sortDirection={sortDirection}
            onSort={onSort}
            totalCost={totalCost}
            totalCurrentValue={totalCurrentValue}
            groupTitle={groupTitle}
          />
        </thead>
        <tbody className={tableClasses.tableBody}>
          {sortItems(items, sortField, sortDirection).map((item, index) => (
            <InventoryTableRow key={index} item={item} groupBy={groupBy} index={index} />
          ))}
        </tbody>
        {/* Keep the footer for convenience when scrolling to the bottom */}
        <tfoot className={tableClasses.tableFoot}>
          <tr>
            <th colSpan={getColspan()} className={`${tableClasses.headerBase} ${tableClasses.headerLeft}`}>
              Total
            </th>
            <th className={`${tableClasses.headerBase} ${tableClasses.headerRight}`}>{formatCurrency(totalCost)}</th>
            <th className={`${tableClasses.headerBase} ${tableClasses.headerRight}`}>
              {formatCurrency(totalCurrentValue)}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default InventoryTable

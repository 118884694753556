import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { gridColumns as customersGridColumns } from '~/containers/customers-page/config'
import { gridColumns as orderProcessingPageGridColumns } from '~/containers/order-processing-page/config'
import productSearchLinkGridColumns from '~/containers/product-search-links-page/config'
import { defaultColumns as shipmentGridColumns } from '~/containers/shipments-grid/config'
import { gridColumns as orderHistoryGridColumns } from '~/containers/order-history-page/config'
import { gridColumns as manifestsGridColumns } from '~/containers/manifests-grid/config'
import { getColumnsConfig as menuItemsGridColumns } from '~/containers/menu-items-page/config'
import { columnsConfig as priorityItemsGridColumns } from '~/containers/priority-items-page/config'
import { PRODUCTS_GRID_COLUMNS } from '../containers/products-page/columns'
import { GridColumn } from '~/components/grid/create-config'
import { MenuItemKindEnum } from '~/types/menu-item'

const __getVisibleGridColumnIds = (columns: GridColumn[]) =>
  _.reject(columns, ['visible', false]).map(column => column.attr || '')

export const getVisibleGridColumnIds = (columns: GridColumn[]) =>
  _.map(
    _.filter(columns, column => {
      return column.visible !== false
    }),
    column => {
      return column.id || column.attr || ''
    }
  )

const visibleProductsGridColumnIds = (columns: GridColumn[]) =>
  _.map(
    _.filter(columns, column => {
      return column.visible !== false
    }),
    column => {
      return column.id || column.attr || ''
    }
  )

export interface PersistableDataState {
  visibleProductsGridColumnIds: string[]
  grid: {
    manifests: string[]
    customers: string[]
    orderProcessing: string[]
    orderHistory: string[]
    productSearchLinks: string[]
    shipments: string[]
    shopfrontMenuItems: string[]
    shopfrontPriorityItems: string[]
    mobileMenuItems: string[]
    mobilePriorityItems: string[]
  }
  activeCategoryFilters: string[] // Not sure what the true type is here. Not sure this is even being used?!
}

const initialState: PersistableDataState = {
  visibleProductsGridColumnIds: visibleProductsGridColumnIds(PRODUCTS_GRID_COLUMNS),
  grid: {
    manifests: __getVisibleGridColumnIds(manifestsGridColumns as GridColumn[]),
    customers: __getVisibleGridColumnIds(customersGridColumns as GridColumn[]),
    orderProcessing: __getVisibleGridColumnIds(orderProcessingPageGridColumns),
    orderHistory: getVisibleGridColumnIds(orderHistoryGridColumns as GridColumn[]),
    productSearchLinks: __getVisibleGridColumnIds(productSearchLinkGridColumns),
    shipments: __getVisibleGridColumnIds(shipmentGridColumns),
    shopfrontMenuItems: __getVisibleGridColumnIds(menuItemsGridColumns(MenuItemKindEnum.SHOPFRONT)),
    shopfrontPriorityItems: __getVisibleGridColumnIds(priorityItemsGridColumns),
    mobileMenuItems: __getVisibleGridColumnIds(menuItemsGridColumns(MenuItemKindEnum.MOBILE)),
    mobilePriorityItems: __getVisibleGridColumnIds(priorityItemsGridColumns)
  },
  activeCategoryFilters: []
}

const persistableDataSlice = createSlice({
  name: 'persistableData',
  initialState,
  reducers: {
    changeVisibleProductsGridColumns: (state, action: PayloadAction<string[]>) => {
      state.visibleProductsGridColumnIds = action.payload
    },
    changeGridVisibleColumns: (
      state,
      action: PayloadAction<{ recordType: keyof PersistableDataState['grid']; visibleColumnIds: string[] }>
    ) => {
      const key = action.payload.recordType
      state.grid[key] = action.payload.visibleColumnIds
    },
    changeProductCategoryFilters: (state, action: PayloadAction<string[]>) => {
      state.activeCategoryFilters = action.payload
    }
  }
})

export const { changeVisibleProductsGridColumns, changeGridVisibleColumns, changeProductCategoryFilters } =
  persistableDataSlice.actions

export default persistableDataSlice.reducer

// Common table styling classes using Tailwind
export const tableClasses = {
  container: 'overflow-x-auto shadow-md rounded-lg',
  table: 'min-w-full divide-y divide-gray-200',
  thead: 'bg-gray-50',
  headerRow: 'divide-x divide-gray-200',
  headerCell: 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
  headerCellRight: 'px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider',
  headerCellCenter: 'px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider',
  tbody: 'bg-white divide-y divide-gray-200',
  row: 'hover:bg-gray-50',
  cell: 'px-6 py-4 whitespace-nowrap text-sm text-gray-700',
  cellRight: 'px-6 py-4 whitespace-nowrap text-right text-sm text-gray-700',
  cellCenter: 'px-6 py-4 whitespace-nowrap text-center text-sm text-gray-700',
  tfoot: 'bg-gray-50',
  footerRow: 'font-semibold',
  footerCell: 'px-6 py-3 text-left text-sm font-medium text-gray-700',
  footerCellRight: 'px-6 py-3 text-right text-sm font-medium text-gray-700',
  link: 'text-blue-600 hover:text-blue-900 hover:underline',
  sortable: 'cursor-pointer select-none flex items-center'
}

import ProductStatusFilter, { ProductStatusFilterStatus } from './product-status-filter'
import ProductInventoryStatusFilter, { ProductInventoryFilterStatus } from './product-inventory-status-filter'
import ProductsTableRow from './products-table-row'
import ProductCategoriesFilter from './product-categories-filter'
import ProductsGridPager from './products-grid-pager'
import Product from '~/types/product'

interface FooterTextProps {
  listMeta: {
    pageSize: number
    currentPage: number
    totalPages: number
    totalCount: number
  }
}

const FooterText = ({ listMeta }: FooterTextProps) => {
  const { pageSize, currentPage, totalPages, totalCount } = listMeta
  const lastPageNum = totalPages

  let text = ''

  if (totalCount === 0) {
    text = `No products found`
  } else if (currentPage > lastPageNum) {
    text = `Page not found`
  } else if (totalCount < pageSize) {
    text = `Showing 1 to ${totalCount} of ${totalCount} products`
  } else {
    const start = pageSize * (currentPage - 1) + 1
    const end = currentPage === lastPageNum ? totalCount : pageSize * currentPage

    text = start > totalCount ? `Page is empty` : `Showing ${start} to ${end} of ${totalCount} products`
  }

  return totalCount === null || totalCount === undefined ? null : <p>{text}</p>
}

export { FooterText }

interface ProductsTableProps {
  listMeta?: FooterTextProps['listMeta']
  filters: {
    page: number
    search: string
    categories: string[]
    status: ProductStatusFilterStatus
    inventory_status?: ProductInventoryFilterStatus
    shelf_location?: string
  }
  onActiveCategoriesChange: (categories: string[]) => void
  columns: {
    id: string
    heading: string
    format?: (product: Product) => string | number | React.ReactNode
  }[]
  list: Product[]
  onStatusChange: (status: ProductStatusFilterStatus) => void
  onInventoryStatusChange?: (status: ProductInventoryFilterStatus) => void
}

const ProductsTable = ({
  listMeta,
  filters,
  onActiveCategoriesChange,
  columns,
  list,
  onStatusChange,
  onInventoryStatusChange
}: ProductsTableProps) => {
  return (
    <div className="products-list">
      <div className="d-flex justify-content-between mb-2">
        {(listMeta?.totalPages || 1) <= 1 && <div></div> /* Fix flex alignment if there is only one page */}
        <ProductsGridPager totalPages={listMeta?.totalPages || 1} filters={filters} />
        <div className="d-flex justify-start items-center gap-2">
          <ProductCategoriesFilter activeCategories={filters.categories} onChange={onActiveCategoriesChange} />
          {onInventoryStatusChange && (
            <ProductInventoryStatusFilter
              inventoryStatus={filters.inventory_status}
              onChange={onInventoryStatusChange}
            />
          )}
        </div>
      </div>

      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column.id}>{column.heading}</th>
            ))}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {list.map(product => (
            <ProductsTableRow key={product.id} product={product} columns={columns} />
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-between">
        <div className="products-count">{listMeta && <FooterText listMeta={listMeta} />}</div>
        <ProductsGridPager totalPages={listMeta?.totalPages || 1} filters={filters} />
      </div>

      <div className="d-flex justify-content-end">
        <ProductStatusFilter status={filters.status} onChange={onStatusChange} />
      </div>
    </div>
  )
}

export default ProductsTable

import { useMemo, useState } from 'react'

import NoResults from './NoResults'
import { InvoiceInventoryReportResponse, DenormalizedInventoryItem, GroupBy } from './types'
import { SortDirection } from './TableComponents'
import { toggleSort } from './SortUtils'
import InventoryGroup from './InventoryGroup'
import GrandTotalDisplay from './GrandTotalDisplay'

interface ProductResultsProps {
  reportData: InvoiceInventoryReportResponse['reportData']
  queryType: InvoiceInventoryReportResponse['queryType']
  queryValue: string | null
  groupBy: GroupBy
}

const ProductResults = ({ reportData, queryType, queryValue, groupBy }: ProductResultsProps) => {
  // State for sorting
  const [sortField, setSortField] = useState<keyof DenormalizedInventoryItem | null>(null)
  const [sortDirection, setSortDirection] = useState<SortDirection>(null)

  // Function to handle sort toggle
  const handleSort = (field: keyof DenormalizedInventoryItem | null) => {
    if (field === null) return
    toggleSort(field, sortField, sortDirection, setSortField, setSortDirection)
  }

  // First denormalize the data
  const denormalizedData = useMemo(() => {
    const result: DenormalizedInventoryItem[] = []

    reportData.forEach(product => {
      product.invoiceDetails.forEach(detail => {
        result.push({
          productId: product.productId,
          productName: product.productName,
          productStatus: product.productStatus,
          invoiceNo: detail.invoiceNo,
          supplierName: detail.supplierName,
          purchaseDate: detail.purchaseDate,
          originalQuantity: detail.originalQuantity,
          currentQuantity: detail.currentQuantity,
          quantitySoldFifo: detail.quantitySoldFifo,
          costPricePerUnit: detail.costPricePerUnit,
          totalCost: detail.totalCost,
          currentValue: detail.currentValue,
          shelfLocation: detail.shelfLocation
        })
      })
    })

    return result
  }, [reportData])

  // Calculate grand totals across all items
  const grandTotals = useMemo(() => {
    const totalCost = denormalizedData.reduce((sum, item) => sum + parseFloat(item.totalCost), 0)
    const totalCurrentValue = denormalizedData.reduce((sum, item) => sum + parseFloat(item.currentValue), 0)

    return { totalCost, totalCurrentValue }
  }, [denormalizedData])

  // Then group the data based on the selected groupBy option
  const groupedData = useMemo(() => {
    // Process the data even if queryType is null
    const result = new Map<string, DenormalizedInventoryItem[]>()

    denormalizedData.forEach(item => {
      let key = ''

      if (groupBy === 'product') {
        key = `${item.productId}-${item.productName}`
      } else if (groupBy === 'invoice_no') {
        key = item.invoiceNo
      } else if (groupBy === 'supplier_name') {
        key = item.supplierName
      }

      if (!result.has(key)) {
        result.set(key, [])
      }

      result.get(key)?.push(item)
    })

    return result
  }, [denormalizedData, groupBy])

  return (
    <div className="space-y-6">
      {/* Title and compact Grand Totals display */}
      {queryType && reportData.length > 0 && (
        <div className="flex flex-wrap items-center">
          <h2 className="text-xl font-semibold text-gray-800">
            Results for {queryType === 'invoice' ? `Invoice #${queryValue}` : `Supplier: ${queryValue}`}
          </h2>
        </div>
      )}

      {/* Full Grand Totals display above results */}
      {reportData.length > 0 && groupedData.size > 1 && (
        <GrandTotalDisplay totalCost={grandTotals.totalCost} totalCurrentValue={grandTotals.totalCurrentValue} />
      )}

      {reportData.length === 0 ? (
        <>
          {queryValue ? (
            <NoResults message="No inventory data found for the selected criteria." />
          ) : (
            <NoResults message="Please search by invoice or supplier to see results" />
          )}
        </>
      ) : (
        <div className="space-y-8">
          {Array.from(groupedData.entries()).map(([groupKey, items]) => (
            <InventoryGroup
              key={groupKey}
              groupKey={groupKey}
              items={items}
              groupBy={groupBy}
              sortField={sortField}
              sortDirection={sortDirection}
              onSort={handleSort}
            />
          ))}

          {/* Grand Totals display below all results */}
          {groupedData.size > 1 && (
            <GrandTotalDisplay totalCost={grandTotals.totalCost} totalCurrentValue={grandTotals.totalCurrentValue} />
          )}
        </div>
      )}
    </div>
  )
}

export default ProductResults

import { formatCurrency } from '~/lib/format-utils'

interface TotalDisplayProps {
  grandTotal: string
  filteredTotal?: string
  supplierCount?: number
  filteredCount?: number
}

const TotalDisplay: React.FC<TotalDisplayProps> = ({ grandTotal, filteredTotal, supplierCount, filteredCount }) => {
  // Display the filtered total if it's provided and different from the grand total
  const isFiltered = filteredTotal && filteredTotal !== grandTotal
  const displayTotal = isFiltered ? filteredTotal : grandTotal

  return (
    <div className="flex justify-between items-center mb-4 p-4 bg-gray-50 border rounded-md shadow-sm">
      <div className="text-lg font-semibold text-gray-700">
        {isFiltered ? (
          <div className="flex flex-col">
            <span>
              Filtered Total: <span className="text-blue-700">{formatCurrency(displayTotal)}</span>
            </span>
            {filteredCount && supplierCount && (
              <span className="text-sm text-gray-500">
                Showing {filteredCount} of {supplierCount} suppliers
              </span>
            )}
          </div>
        ) : (
          <>
            Grand Total: <span className="text-gray-500">{formatCurrency(displayTotal)}</span>
          </>
        )}
      </div>

      {isFiltered && (
        <div className="text-sm text-gray-600">
          <div>Full Total: {formatCurrency(grandTotal)}</div>
        </div>
      )}
    </div>
  )
}

export default TotalDisplay

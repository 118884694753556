import { library } from '@fortawesome/fontawesome-svg-core'

import { faChartSimple } from '@fortawesome/free-solid-svg-icons/faChartSimple'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faGlobeAsia } from '@fortawesome/free-solid-svg-icons/faGlobeAsia'
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faMobile } from '@fortawesome/free-solid-svg-icons/faMobile'
import { faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillAlt'
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox'
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck'
import { faEbay } from '@fortawesome/free-brands-svg-icons/faEbay'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign'
import { faFileAlt as faFileAltSolid } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { faFileAlt as faFileAltRegular } from '@fortawesome/free-regular-svg-icons/faFileAlt'
import { faAddressBook } from '@fortawesome/free-regular-svg-icons/faAddressBook'

import { faToggleOn } from '@fortawesome/free-solid-svg-icons/faToggleOn'
import { faToggleOff } from '@fortawesome/free-solid-svg-icons/faToggleOff'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons/faArrowsAlt'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons/faArrowsRotate'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faTshirt } from '@fortawesome/free-solid-svg-icons/faTShirt'
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks'
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort'

library.add(
  faChartSimple,
  faUser,
  faTshirt,
  faHistory,
  faTasks,
  faFileImport,
  faSignOutAlt,
  faUsers,
  faAddressBook,
  faBars,
  faShoppingCart,
  faTimes,
  faCheck,
  faSearch,
  faCogs,
  faCog,
  faGlobeAsia,
  faFlag,
  faEnvelope,
  faMobile,
  faMoneyBillAlt,
  faBox,
  faTruck,
  faEbay,
  faFacebook,
  faIntercom,
  faDollarSign,
  faFileAltSolid,
  faFileAltRegular,
  faToggleOn,
  faToggleOff,
  faExclamationCircle,
  faMinus,
  faPlus,
  faTimesCircle,
  faPencilAlt,
  faArrowsAlt,
  faTrash,
  faMapMarker,
  faExternalLinkAlt,
  faQuestionCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faFileExcel,
  faInfoCircle,
  faArrowLeft,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowsRotate,
  faHome,
  faSort
)

import { Link } from 'react-router-dom'
import { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Field, useFormikContext } from 'formik'
import classnames from 'classnames'

import { printCurrency, calculateLineTotal } from './utils'
import Icon from '~/components/icon'
import { Button } from '~/components/forms'
import { BsInput, CounterInputField } from '~/components/form-inputs/formik/inputs-decorated'
import { PosFormLineItemValue, PosFormValues } from './form'

export const Lines = styled.div`
  margin-left: -10px;
  margin-right: -10px;
`

export const LineHeadings = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 0;
  font-weight: 600;
  text-align: center;
`

export const Line = styled(LineHeadings)`
  font-weight: 400;
  align-items: stretch;
  position: relative;
  padding: 10px;
  margin-left: -10px;
  margin-right: -20px;
`

export const ColView = styled.div`
  min-width: 37px;
`

export const ColGroup = styled.div`
  display: flex;
  align-items: baseline;
  flex-basis: 300px;
  flex-grow: 1;
  margin-right: 10px;
  min-width: 0; // needed to make truncated text work
`

export const Col = styled.div`
  flex-basis: 100px;
  margin-right: 10px;
  flex-shrink: 0;
  min-width: 0; // needed to make truncated text work

  // truncate text with ellipsis, so it doesn't spread to multiple lines
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: auto;
  }

  &.links,
  &.available,
  &.quantity,
  &.unit-price,
  &.line-total {
    &,
    input {
      text-align: right;
    }
  }

  &.links {
    flex-basis: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 33px;
  }
  &.available {
    flex-basis: 70px;

    &.cell {
      padding-top: 6.25px;
      padding-right: 10px;
    }
  }
`

export const ColA = styled(Col)`
  flex-basis: 150px;
  text-align: left;
`

export const ColB = styled(Col)`
  flex-grow: 1;
  flex-basis: 150px;
  text-align: left;
`

export const ColDelete = styled(Col)`
  display: none;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: -5px;

  // target parent Line's hover
  ${Line}:hover & {
    display: block;
  }
`

export interface RenderLineItemsProps {
  index: number
  lineItem: PosFormLineItemValue
  onLineClicked: (lineItem: PosFormLineItemValue) => void
}

const RenderLineItem = ({ index, lineItem, onLineClicked }: RenderLineItemsProps) => {
  const { setFieldValue, values } = useFormikContext<PosFormValues>()
  const { deletedLineItems, lineItems } = values

  useEffect(() => {
    const lineTotal = calculateLineTotal(lineItem.quantity, lineItem.unitPrice)
    setFieldValue(`lineItems[${index}].subtotal`, lineTotal)
  }, [index, lineItem, setFieldValue])

  const onRemove = useCallback(
    (index: number) => {
      setFieldValue(
        'lineItems',
        lineItems.filter((_, i) => i !== index)
      )
      const { id } = lineItems[index]
      if (id) {
        setFieldValue('deletedLineItems', [...deletedLineItems, { id, _destroy: '1' }])
      }
    },
    [deletedLineItems, lineItems, setFieldValue]
  )

  const isMobile = lineItem.product?.mobile ?? false
  const isOutOfStock =
    ((isMobile ? lineItem.product?.otherQuantityAvailable : lineItem.product?.quantityAvailable) ?? 0) <= 0

  return (
    <Line>
      <ColView>
        <Button variant="link" onClick={() => onLineClicked(lineItem)}>
          <Icon icon="circle-info" className="text-primary" />
        </Button>
      </ColView>
      <ColGroup>
        <ColA>
          <Field name={`lineItems[${index}].product.customSku`} component={BsInput} disabled readOnly label={null} />
        </ColA>
        <ColB>
          <Field name={`lineItems[${index}].product.name`} component={BsInput} disabled readOnly label={null} />
        </ColB>
      </ColGroup>
      <Col className="quantity">
        <Field name={`lineItems[${index}].quantity`} component={CounterInputField} label={null} />
      </Col>
      <Col className="unit-price">
        <Field
          name={`lineItems[${index}].unitPrice`}
          component={BsInput}
          label={null}
          type="number"
          step="0.01"
          min={1}
          max={9999}
        />
      </Col>
      <Col className="line-total">
        <Field
          name={`lineItems[${index}].subtotal`}
          component={BsInput}
          disabled
          readOnly
          label={null}
          formatFn={(value: number) => printCurrency(value, '')}
        />
      </Col>
      <Col className={classnames('available cell', isOutOfStock && 'text-danger text-bold')}>
        {isMobile ? lineItem.product?.otherQuantityAvailable : lineItem.product?.quantityAvailable}
      </Col>
      <Col className="links">
        {lineItem.product && (
          <Link to={`/products/${lineItem.product.id}/edit`} target="_blank">
            <Icon icon="external-link-alt" />
          </Link>
        )}
      </Col>
      <ColDelete>
        <Icon icon="times" className="text-danger" onClick={() => onRemove(index)} />
      </ColDelete>
    </Line>
  )
}

export default RenderLineItem

import { Modal } from 'react-bootstrap'
import { useEffect, useRef } from 'react'

import Customer from '~/types/customer'
import CustomerSearchInput from './customer-search-input'

interface SelectCustomerDialogProps {
  show: boolean
  onHide: () => void
  onSelect: (customer: Customer) => void
}

const SelectCustomerDialog = ({ show, onHide, onSelect }: SelectCustomerDialogProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (show && inputRef.current) {
      // Focus the input when the modal is shown
      setTimeout(() => {
        inputRef.current?.focus()
      }, 100) // Small delay to ensure the modal is fully rendered
    }
  }, [show])

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerSearchInput onSelect={onSelect} onHide={onHide} inputRef={inputRef} />
      </Modal.Body>
    </Modal>
  )
}

export default SelectCustomerDialog

import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

import useAuth from '~/contexts/useAuth'
import { UserKindEnum } from '~/types/user'
import Spinner from '~/components/spinner'
import { ApiErrorResponse, SupplierStockReportResponse, ProductStatus } from './types'
import StatusFilterForm from './StatusFilterForm'
import SupplierTable from './SupplierTable'
import { ErrorMessage, NoResults } from './TableComponents'
import { SortState, toggleSort } from './SortUtils'
import TotalDisplay from './TotalDisplay'

const StockOnHandValueBySupplierReport: React.FC = () => {
  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [reportData, setReportData] = useState<SupplierStockReportResponse['reportData']>([])
  const [grandTotal, setGrandTotal] = useState<string>('0')
  const [filteredTotal, setFilteredTotal] = useState<string>('0')
  const [generatedAt, setGeneratedAt] = useState<Date | null>(null)
  const [statusFilter, setStatusFilter] = useState<ProductStatus | ''>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sortState, setSortState] = useState<SortState>({
    field: 'supplier',
    direction: 'asc'
  })

  // URL params for bookmarking/sharing
  const [searchParams, setSearchParams] = useSearchParams()

  // Fetch report data
  const fetchReport = useCallback(
    async (status: ProductStatus | '' = '') => {
      setLoading(true)
      setError(null)

      try {
        const params: Record<string, string> = {}
        if (status) {
          params.status = status
        }

        // Update URL params
        const urlParams = new URLSearchParams()
        if (status) urlParams.set('status', status)
        setSearchParams(urlParams)

        const response = await axios.get<SupplierStockReportResponse | ApiErrorResponse>(
          '/api/inventory/stock_on_hand_value_by_supplier_report',
          { params }
        )

        if (response.data.success) {
          const data = response.data as SupplierStockReportResponse
          setReportData(data.reportData)
          setGrandTotal(data.grandTotal)
          setGeneratedAt(new Date(data.generatedAt))
        } else {
          const errorData = response.data as ApiErrorResponse
          setError(errorData.error || 'Failed to fetch report data')
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          setError(`Error: ${err.message}`)
        } else {
          setError('An unknown error occurred')
        }
      } finally {
        setLoading(false)
      }
    },
    [setSearchParams]
  )

  // Handle status filter change
  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newStatus = e.target.value as ProductStatus | ''
      setStatusFilter(newStatus)
      fetchReport(newStatus)
    },
    [fetchReport]
  )

  // Handle refresh button click
  const handleRefresh = useCallback(() => {
    fetchReport(statusFilter)
  }, [fetchReport, statusFilter])

  // Handle search term change
  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  // Handle sort change
  const handleSortChange = useCallback((field: string) => {
    setSortState(prevState => toggleSort(field, prevState))
  }, [])

  // Handle filtered total change
  const handleFilteredTotalChange = useCallback((total: string) => {
    setFilteredTotal(total)
  }, [])

  // Load initial data and handle URL params
  useEffect(() => {
    const loadInitialData = () => {
      const statusParam = searchParams.get('status') || ''

      if (statusParam) {
        setStatusFilter(statusParam as ProductStatus)
      }

      fetchReport(statusParam as ProductStatus)
    }

    loadInitialData()

    // Add event listener for popstate (browser back/forward)
    const handlePopState = () => {
      window.location.reload()
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [fetchReport, searchParams])

  // Security check
  const { userProfile } = useAuth()
  if (userProfile?.kind !== UserKindEnum.OWNER) return null

  return (
    <div className="max-w-6xl mx-auto">
      <StatusFilterForm
        statusFilter={statusFilter}
        onStatusChange={handleStatusChange}
        onRefresh={handleRefresh}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
      />

      {loading ? (
        <Spinner label="Loading report data..." />
      ) : error ? (
        <ErrorMessage message={error} />
      ) : (
        <>
          {generatedAt && (
            <div className="mb-4 text-sm text-gray-500 italic flex items-center">
              <span>Report generated at: {generatedAt.toLocaleString()}</span>
              {statusFilter && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
                  Filtered by: {statusFilter === 'active' ? 'Active Products' : 'Inactive Products'}
                </span>
              )}
            </div>
          )}

          {reportData.length > 0 && (
            <TotalDisplay
              grandTotal={grandTotal}
              filteredTotal={searchTerm ? filteredTotal : undefined}
              supplierCount={reportData.length}
              filteredCount={
                searchTerm
                  ? reportData.filter(s => s.supplier?.toLowerCase().includes(searchTerm.toLowerCase())).length
                  : undefined
              }
            />
          )}

          {reportData.length === 0 ? (
            <NoResults message="No stock data available for the selected filter." />
          ) : (
            <SupplierTable
              suppliers={reportData}
              grandTotal={grandTotal}
              statusFilter={statusFilter}
              searchTerm={searchTerm}
              sortState={sortState}
              onSortChange={handleSortChange}
              onFilteredTotalChange={handleFilteredTotalChange}
            />
          )}
        </>
      )}
    </div>
  )
}

export default StockOnHandValueBySupplierReport

import { formatCurrency } from '~/lib/format-utils'
import { tableClasses } from './table-classes'

interface GrandTotalDisplayProps {
  totalCost: number
  totalCurrentValue: number
  isCompact?: boolean
}

const GrandTotalDisplay: React.FC<GrandTotalDisplayProps> = ({ totalCost, totalCurrentValue, isCompact = false }) => {
  if (isCompact) {
    return (
      <div className="inline-flex items-center ml-4 pl-4 border-l border-gray-300">
        <div className="flex gap-4">
          <div className="text-sm">
            <span className="font-medium text-gray-700">Grand Total:</span>{' '}
            <span className={tableClasses.totalsValue}>{formatCurrency(totalCost)}</span>
            <span className="mx-1 text-gray-500">→</span>
            <span className={tableClasses.totalsValue}>{formatCurrency(totalCurrentValue)}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-gray-100 border rounded-lg shadow-sm p-4 mb-6">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h3 className="text-lg font-medium text-gray-700 mb-2 md:mb-0">Grand Totals</h3>
        <div className="flex flex-col md:flex-row gap-4 md:gap-8">
          <div>
            <span className="text-sm text-gray-500">Total Cost:</span>{' '}
            <span className="text-lg font-semibold text-gray-900">{formatCurrency(totalCost)}</span>
          </div>
          <div>
            <span className="text-sm text-gray-500">Total SOH Value:</span>{' '}
            <span className="text-lg font-semibold text-gray-900">{formatCurrency(totalCurrentValue)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GrandTotalDisplay

import { object, number, string, array } from 'yup'
import '~/lib/yup'

import { isDomestic } from './utils'

const LineItemSchema = object().shape({
  quantity: number()
    .required()
    .test('quantity-available-check', 'exceeds available stock', function (value) {
      const lineItem = this.from?.[0].value
      const { quantityAvailable } = lineItem
      // If quantityAvailable is null or undefined, skip the check
      if (quantityAvailable === null || quantityAvailable === undefined) {
        return true
      }
      // Otherwise, ensure quantity doesn't exceed quantityAvailable
      return value <= quantityAvailable
    }),
  unitPrice: number().required(),
  quantityAvailable: number().min(1).optional().nullable()
})

export const validationSchema = object({
  customerId: number().required(),
  shippingService: string().required(),
  customerEmail: string().required(),
  shippingContactName: string().required(),
  shippingAddress1: string().required(),
  shippingCity: string().required(),
  shippingCountry: string().required(),
  shippingPostcode: string().required(),
  shippingState: string()
    .nullable()
    .test('is-state-required', 'is required', function (value) {
      if (!value && isDomestic(this.from?.[0].value?.shippingCountry)) return false
      else return true
    }),
  lineItems: array().of(LineItemSchema)
})

export default validationSchema

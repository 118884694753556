import numeral from 'numeral'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { ProductFormValues } from './use-product-form'
import RenderStockHistory from './render-stock-history'
import { AppState } from '~/config/store'
import CopyToAccountModal from './copy-to-account-modal'
import useAuth from '~/contexts/useAuth'

interface InventoryManagementProps {
  formValues: ProductFormValues
}

const InventoryManagementAdvanced = ({ formValues }: InventoryManagementProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { userProfile } = useAuth()
  const summary = useSelector((state: AppState) => state.productsPage.currentProductInventorySummary)
  const existsInAccounts = useSelector((state: AppState) => state.productsPage.currentProductExistsInAccounts) || []
  const canCopyToAccount = !existsInAccounts.map(a => a.subdomain).includes('cca') && formValues.id

  return (
    <div className="row">
      <div className="col-sm-12">
        {summary?.quantityAvailable !== undefined && (
          <fieldset>
            <div className="d-flex justify-content-between">
              <legend>Inventory Summary</legend>

              <button
                type="button"
                className="btn btn-primary text-nowrap"
                onClick={() => setIsOpen(true)}
                disabled={!canCopyToAccount}>
                Copy to Account
              </button>
            </div>

            <table className="table table-nonfluid">
              <tbody>
                {formValues.mobile && (
                  <tr>
                    <th className="text-end">Currently advertised in mobile store:</th>
                    <td>{numeral(summary.onlineQuantityAvailable).format('0,0')}</td>
                  </tr>
                )}
                {formValues.mobile && (
                  <tr>
                    <th className="text-end">Available for POS / eBay:</th>
                    <td>{numeral(summary.otherQuantityAvailable).format('0,0')}</td>
                  </tr>
                )}
                <tr>
                  <th className="text-end">Awaiting processing:</th>
                  <td>{numeral(summary.quantitySoldUnprocessed).format('0,0')}</td>
                </tr>
                <tr>
                  <th className="text-end">Total quantity in stock:</th>
                  <td>{numeral(summary.quantityAvailable).add(summary.quantitySoldUnprocessed).format('0,0')}</td>
                </tr>
                {summary.stockOnHandValue !== null && (
                  <tr>
                    <th className="text-end">Stock on Hand Value:</th>
                    <td>{numeral(summary.stockOnHandValue).format('$0,0.00')}</td>
                  </tr>
                )}
                {summary.totalSales !== null && (
                  <tr>
                    <th className="text-end">Total Sales:</th>
                    <td>{numeral(summary.totalSales).format('$0,0.00')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </fieldset>
        )}

        <fieldset className="mt-3">
          <legend>Stock History</legend>
          <RenderStockHistory showTotals readOnly={userProfile?.kind !== 'owner'} />
        </fieldset>

        {canCopyToAccount && formValues.id && (
          <CopyToAccountModal
            show={isOpen}
            onHide={() => setIsOpen(false)}
            productId={formValues.id}
            onSuccess={async _product => setIsOpen(false)}>
            <p>This will copy the product meta data, including Stock History and any pictures to the CCA account.</p>
          </CopyToAccountModal>
        )}
      </div>
    </div>
  )
}

export default InventoryManagementAdvanced

// Reusable Tailwind classes
export const tableClasses = {
  headerBase: 'px-4 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider',
  headerSortable: 'cursor-pointer hover:bg-gray-100',
  headerLeft: 'text-left',
  headerRight: 'text-right',
  cell: 'px-4 py-3 whitespace-nowrap text-sm text-gray-900',
  cellRight: 'text-right',
  link: 'text-blue-600 hover:text-blue-800 hover:underline',
  table: 'min-w-full divide-y divide-gray-200',
  tableHead: 'bg-gray-50',
  tableBody: 'bg-white divide-y divide-gray-200',
  tableFoot: 'bg-gray-50',
  row: 'hover:bg-gray-50',
  card: 'bg-white rounded-lg shadow overflow-hidden',
  cardHeader: 'p-4 bg-gray-50 border-b border-gray-200',
  groupTitleRow: 'bg-gray-100 border-b border-gray-200',
  groupTitleCell: 'px-4 py-3 text-left text-sm font-medium text-gray-700',
  totalsHeader: 'bg-gray-100 border-b border-gray-200 text-sm font-medium',
  totalsValue: 'text-blue-700 font-semibold'
}
